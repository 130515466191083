import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./LogItem.module.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Alert from "@material-ui/lab/Alert";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import firebase from "firebase";
import TablePagination from "@material-ui/core/TablePagination";

function logmessage(entity, operation, payload) {
  if (entity === "Data") {
    return `Data for the survey ${payload.survey} was read`;
  } else if (entity === "Results") {
    return `Report for the survey ${payload.survey} was read`;
  } else if (entity === "Survey Invite") {
    return `Survey Invite for the survey - ${payload.survey} was sent to ${payload.email}`;
  } else if (entity === "Survey Report") {
    return `Survey Report for the survey - ${payload.survey} was sent to ${payload.email}`;
  } else if (entity === "Company" && operation === "CREATED") {
    return `A new company, ${payload.name} was registered.`;
  } else if (entity === "Company" && operation === "UPDATED") {
    return `The details for this company were changed to Name:${payload.name}, CIN:${payload.cin}, Address:${payload.address}`;
  } else if (entity === "Users" && operation === "CREATED") {
    return `A new user, ${payload.email} was created with ${payload.role} rights`;
  } else if (entity === "Users" && operation === "READ") {
    return `The Users list of this company was read`;
  } else if (entity === "Surveys" && operation === "CREATED") {
    return `A new survey - ${payload.content.title}, was created`;
  } else if (entity === "Users" && operation === "DELETED") {
    return `The user- ${payload.email} having ${payload.role} rights was removed from this company.`;
  } else if (entity === "Surveys" && operation === "UPDATED") {
    return `The survey,${payload.title}, was edited`;
  } else if (entity === "Survey Status" && operation === "UPDATED") {
    if (payload.status === false) {
      return `The survey,${payload.title}'s status was changed to NOT accepting responses`;
    } else {
      return `The survey,${payload.title}'s status was changed to ACCEPTING responses`;
    }
  } else if (entity === "Surveys" && operation === "DELETED") {
    return `The survey, ${payload.content.title} was deleted.`;
  } else if (entity === "Consolidated Survey Invites" && operation === "SENT") {
    return `The consolidated survey invite for ${payload.number} ${
      payload.number === 1 ? "survey" : "surveys"
    } was sent to: ${payload.email}.`;
  }
}

class LogItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsPerPage: 25,
      page: 0,
    };
  }

  componentDidMount() {
    firebase.analytics().logEvent("Activity Log page is rendered");
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    return (
      <div>
        <Alert severity="info" style={{ margin: "25px" }}>
          Activity Log updates realtime, as soon as a new log arrives it is
          automatically shown
        </Alert>
        <div className={classes.item} style={{ margin: "25px" }}>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100, 200]}
            component="div"
            count={this.props.logs.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead
                style={{ border: "1px solid #ddd" }}
                className={classes.head}
              >
                <TableRow>
                  {/* <TableCell align="center" style={{ color: "white", fontSize: "17px" }}>Name</TableCell> */}
                  <TableCell
                    align="center"
                    style={{ color: "white", fontSize: "17px" }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ color: "white", fontSize: "17px" }}
                  >
                    Activity Performed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ color: "white", fontSize: "17px" }}
                  >
                    Reference
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ color: "white", fontSize: "17px" }}
                  >
                    Message
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ color: "white", fontSize: "17px" }}
                  >
                    Date And Time
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.logs
                  .slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage +
                      this.state.rowsPerPage
                  )
                  .map((log) => (
                    <TableRow key={log.name} className={classes.row}>
                      {/* <TableCell component="th" scope="row" align="center" className={classes.td}>
                                            {log.name}
                                        </TableCell> */}
                      <TableCell align="center" className={classes.td}>
                        {log.email}
                      </TableCell>
                      <TableCell align="center" className={classes.td}>
                        <Chip
                          label={log.operation}
                          style={{
                            backgroundColor: "#34baf2",
                            color: "white",
                            fontWeight: "bold",
                          }}
                        ></Chip>
                      </TableCell>
                      <TableCell align="center" className={classes.td}>
                        {log.entity}
                      </TableCell>
                      <TableCell align="center" className={classes.td}>
                        {logmessage(log.entity, log.operation, log.payload)}
                      </TableCell>
                      <TableCell align="center" className={classes.td}>
                        {moment(log.timestamp).format("DD/MM/YYYY [at] h:mm a")}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(LogItem);
