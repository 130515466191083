import * as actionTypes from "./actionsTypes";
import firebase from "firebase";
import { QuestionTypes } from "../../ulitity/constants/Questions";
import keyBy from "lodash/keyBy";
import { db } from "../../firebase-config";

export const fetchResults = (surveyId) => {
  return db
    .collection(`results/${surveyId}/resultsList`)
    .get()
    .then((docs) => {
      const resultsData = [];
      docs.forEach((doc) => resultsData.push({ ...doc.data() }));
      return resultsData;
    });
};

export const fetchSurvey = async (surveyId, compId) => {
  return db
    .collection(`surveys/${localStorage.getItem("companyid")}/surveysList`)
    .doc(String(surveyId))
    .get()
    .then((doc) => {
      if (!doc.exists) return;

      const docData = { ...doc.data(), id: doc.id };
      const contentData = docData.content;

      return contentData;
    });
};

export const fetchDataStart = () => {
  return {
    type: actionTypes.FETCH_DATA_START,
  };
};

export const fetchResultSuccess = (results) => {
  return {
    type: actionTypes.FETCH_RESULT_SUCCESS,
    payload: results,
  };
};

export const fetchSurveySuccess = (survey) => {
  return {
    type: actionTypes.FETCH_DATA_SURVEY_SUCCESS,
    payload: survey,
  };
};

export const fetchDataFail = (err) => {
  return {
    type: actionTypes.FETCH_DATA_FAIL,
    error: err,
  };
};

export const selectAll = (results) => {
  let newState = {};
  results.forEach((result) => {
    newState[result.id] = true;
  });
  return {
    type: actionTypes.ROW_SET_ALL,
    payload: newState,
  };
};

export const toggleRowSelect = (id) => ({
  type: actionTypes.TOGGLE_ROW_SELECT,
  payload: id,
});

export const unSelectAll = (results) => {
  let newState = {};
  results.forEach((result) => {
    newState[result.id] = false;
  });
  return {
    type: actionTypes.ROW_SET_ALL,
    payload: newState,
  };
};

export const fetchData = (surveyId, token, compId) => (dispatch) => {
  dispatch(fetchDataStart());
  return Promise.all([fetchSurvey(surveyId, compId), fetchResults(surveyId)])
    .then((values) => {
      dispatch(fetchSurveySuccess(values[0]));

      dispatch(fetchResultSuccess(values[1]));

      dispatch(unSelectAll(values[1]));
    })
    .catch((err) => {
      dispatch(fetchDataFail(err));
    });
};

const resultToText = {
  [QuestionTypes.CHECKBOXES]: (question, result) => {
    return question.options
      .filter((option) => result[option._id])
      .map((option) => option.content)
      .join(", ");
  },
  [QuestionTypes.MULTI_LINE_TEXT]: (question, result) => {
    return result;
  },
  [QuestionTypes.SINGLE_LINE_TEXT]: (question, result) => {
    return result;
  },
  [QuestionTypes.DROPDOWN]: (question, result) => {
    return question.options.find((option) => option._id === result)?.content;
  },
  [QuestionTypes.MULTI_CHOICE]: (question, result, resultData) => {
    let resultString = question.options.find(
      (option) => option._id === result
    )?.content;
    return resultString;
  },
  [QuestionTypes.RATING]: (question, result) => {
    return result;
  },
};

export const resultsToGrid = (state) => {
  let { survey, results } = state;

  if (!survey || !survey.questions) {
    return {
      columns: [],
      results: [],
      opencolumns: [],
      openresults: [],
    };
  }

  let opencolumns = survey.questions
    .filter(
      (question) =>
        question.type === "SINGLE_LINE_TEXT" ||
        question.type === "MULTI_LINE_TEXT"
    )
    .map((question, index) => {
      return {
        columnName: question._id,
        displayName: question.title,
      };
    });

  let columns = survey.questions.map((question, index) => {
    if (question.otherOptionIncluded) {
      return [
        {
          columnName: question._id,
          displayName: question.title,
          type: question.type,
        },
        {
          columnName: question._id + "_remarks",
          displayName: question.otherOptionValue,
        },
      ];
    }
    return {
      columnName: question._id,
      displayName: question.title,
      type: question.type,
    };
  });

  columns = columns.flat();

  let questionTypeMap = {};
  survey.questions.forEach((question) => {
    questionTypeMap[question._id] = question.type;
  });

  let opentextResults = results.map((result, index) => {
    let openresultMap = {
      id: result.id,
      _rev: result._rev,
    };
    survey.questions.forEach((question) => {
      let questionResult = result?.result?.[question._id];
      if (
        question.type === "SINGLE_LINE_TEXT" ||
        question.type === "MULTI_LINE_TEXT"
      ) {
        openresultMap[question._id] = questionResult
          ? resultToText[question.type](question, questionResult)
          : "";
      }
    });
    return openresultMap;
  });

  let textResults = results.map((result, index) => {
    let resultMap = {
      id: result.id,
      _rev: result._rev,
    };
    survey.questions.forEach((question) => {
      let questionResult = result?.result?.[question._id];
      resultMap[question._id] = questionResult
        ? resultToText[question.type](question, questionResult, result)
        : "";
      if (question.otherOptionIncluded) {
        resultMap[question._id + "_remarks"] =
          result.result[question._id + "_remarks"];
      }
    });
    return resultMap;
  });
  return {
    columns,
    results: textResults,
    opencolumns,
    openresults: opentextResults,
  };
};

export const getRowSelects = (state) => state.rowSelects;
export const getAllSelected = (state) =>
  Object.keys(state.rowSelects).length &&
  !Object.keys(state.rowSelects).some((id) => !state.rowSelects[id]);

export const deleteResults = (results, surveyId) => {
  return Promise.all(
    results.map((result) =>
      db
        .collection(`results/${surveyId}/resultsList`)
        .doc(String(result.id))
        .delete()
    )
  );
};

export const deleteRows = (deleteds, surveyId) => (dispatch) => {
  return deleteResults(deleteds, surveyId).then(() => {
    let deletedMap = keyBy(deleteds, (e) => e.id);
    dispatch({
      type: actionTypes.DELETE_ROW,
      payload: deletedMap,
    });
  });
};

export const openModal = (result) => {
  return {
    type: actionTypes.OPEN_MODAL,
    payload: result,
  };
};

export const closeModal = () => {
  return {
    type: actionTypes.CLOSE_MODAL,
  };
};

export const getColumns = (state) =>
  state.survey.questions.map((question) => {
    return {
      columnName: question._id,
      displayName: question.title,
    };
  });
