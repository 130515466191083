import * as actionTypes from "./actionsTypes";
import {
  InitQuestions,
  QuestionTypes,
} from "../../ulitity/constants/Questions";
import tabTypes from "../../ulitity/constants/TabTypes";
import newId from "../../ulitity/idGenerator";
import firebase from "firebase";
import { db } from "../../firebase-config";

//--------------------------------------------------------------------------------------------------
export const normalizeSurvey = (survey) => {
  let questions = {};
  survey.questions.forEach((question) => {
    questions[question._id] = question;
  });
  let question_order = survey.questions.map((question) => question._id);
  if (survey?.type === actionTypes.COMPARATIVE) {
    return {
      allQuestionsObject: survey.allQuestionsObject || {},
      title: survey.title,
      subTitle: survey.subTitle,
      questions: questions,
      question_order: question_order,
      current_question_id: "",
      creatorDate: survey.creatorDate,
      lastModified: survey.lastModified,
      openTill: survey.openTill,
      submitting: survey.submitting,
      type: survey.type || "",
      surveyee_count: survey.surveyee_count || 0,

      ungrouped_questions: survey.ungrouped_questions || [],

      grouped_questions: survey.grouped_questions || [],
      surveyee_list: survey.surveyee_list || [],
      survey_saved: true,
      ratingIndex: survey.ratingIndex || "",
      ratingIndexAsFootnote: survey.ratingIndexAsFootnote || false,
      auto_scroll: false,
    };
  }
  return {
    title: survey.title,
    subTitle: survey.subTitle,
    questions: questions,
    allQuestionsObject: survey.allQuestionsObject || {},
    question_order: question_order,
    current_question_id: "",
    creatorDate: survey.creatorDate,
    lastModified: survey.lastModified,
    type: actionTypes.REGULAR,
    openTill: survey.openTill,
    submitting: survey.submitting,
    survey_saved: true,
    auto_scroll: false,
    ungrouped_questions: survey.ungrouped_questions || [...question_order],
    grouped_questions: survey.grouped_questions || [],
  };
};

export const assembleSurvey = (survey) => {
  const {
    subTitle,
    questions,
    creatorDate,
    submitting,
    openTill,
    grouped_questions = [],
    ungrouped_questions = [],
    surveyee_list = [],
    surveyee_count = 0,
    ratingIndex,
    ratingIndexAsFootnote,
  } = survey;
  const lastModified = Date();
  const orderQuestions = survey.question_order.map(
    (questionId) => questions[questionId]
  );
  // const orderQuestions =
  //   survey?.type === actionTypes.COMPARATIVE
  //     ? ungrouped_questions.map((id) => questions[id])
  //     : survey.question_order.map((id) => questions[id]);
  const title = survey.title.trim();
  if (survey?.type === actionTypes.COMPARATIVE) {
    let type = survey?.type;
    return {
      title,
      subTitle,
      openTill,
      questions: [...orderQuestions],
      allQuestionsObject: { ...questions },
      creatorDate,
      lastModified,
      submitting,
      type,
      grouped_questions: grouped_questions,
      ungrouped_questions: ungrouped_questions,
      surveyee_count: surveyee_count,
      surveyee_list: surveyee_list,
      ratingIndex: ratingIndex,
      ratingIndexAsFootnote: ratingIndexAsFootnote,
    };
  }

  return {
    title,
    subTitle,
    openTill,
    questions: [...orderQuestions],
    allQuestionsObject: { ...questions },
    creatorDate,
    lastModified,
    submitting,
    type: actionTypes.REGULAR,
    surveyee_count: 0,
    surveyee_list: [],
    grouped_questions: grouped_questions || [],
    ungrouped_questions: ungrouped_questions || [...orderQuestions],
  };
};
//---------------------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------------------
export const fetchSurveyStart = () => {
  return {
    type: actionTypes.FETCH_SURVEY_START,
  };
};

export const fetchSurveySuccess = (survey) => {
  return {
    type: actionTypes.FETCH_SURVEY_SUCCESS,
    payload: normalizeSurvey(survey),
  };
};

export const fetchSurveyFail = (err) => {
  return {
    type: actionTypes.FETCH_SURVEY_FAIL,
    error: err,
  };
};

export const fetchSurvey = (surveyId, token, compId) => {
  return (dispatch) => {
    dispatch(fetchSurveyStart());

    db.collection(`surveys/${compId}/surveysList`)
      .doc(String(surveyId))
      .get()
      .then((doc) => {
        if (!doc.exists) return;

        const docData = { ...doc.data() };
        dispatch(fetchSurveySuccess(docData?.content));
      })
      .catch((err) => dispatch(fetchSurveyFail(err)));
  };
};
//------------------------------------------------------------------------------------------------

export const updateSurveyStart = () => {
  return {
    type: actionTypes.UPDATE_SURVEY_START,
  };
};

export const updateSurveySuccess = () => {
  return {
    type: actionTypes.UPDATE_SURVEY_SUCCESS,
  };
};

export const updateSurveyFail = (err) => {
  return {
    type: actionTypes.UPDATE_SURVEY_FAIL,
    error: err,
  };
};
export const groupQuestions = (data) => {
  return {
    type: actionTypes.GROUP_QUESTIONS,
    payload: data,
  };
};
export const updateSurvey = (surveyId, token, data, compId) => {
  return (dispatch) => {
    // debugger;

    dispatch(updateSurveyStart());

    db.collection(`surveys/${compId}/surveysList`)
      .doc(String(surveyId))
      .get()
      .then((doc) => {
        if (!doc.exists) return;

        const docData = { ...doc.data() };
        const payload = { ...docData, content: data };
        db.collection(`surveys/${compId}/surveysList`)
          .doc(String(surveyId))
          .update(payload)
          .then(() => {
            db.collection(`logs/${compId}/logsList`)
              .add({
                name: firebase.auth().currentUser.displayName,
                email: firebase.auth().currentUser.email,
                timestamp: new Date().getTime(),
                entity: "Surveys",
                operation: "UPDATED",
                payload: data,
              })
              .then((res) => {
                dispatch(updateSurveySuccess());
              })
              .catch((err) => {
                alert(err);
              });
          })
          .catch((err) => dispatch(fetchSurveyFail(err)));
      })
      .catch((err) => console.log(err));
  };
};

//------------------------------------------------------------------------------------------------
export const toggleSubmitSuccess = () => {
  return {
    type: actionTypes.TOGGLE_SUBMIT_SUCCESS,
  };
};

export const toggleSubmitFail = (err) => {
  return {
    type: actionTypes.TOGGLE_SUBMIT_FAIL,
    error: err,
  };
};

export const toggleSubmit = (surveyId, token, submit, compId, title) => {
  return (dispatch) => {
    db.collection(`surveys/${compId}/surveysList`)
      .doc(String(surveyId))
      .get()
      .then((doc) => {
        if (!doc.exists) return;

        const docData = { ...doc.data() };
        const payload = {
          ...docData,
          content: { ...docData.content, submitting: submit },
        };
        db.collection(`surveys/${compId}/surveysList`)
          .doc(String(surveyId))
          .update(payload)
          .then(() => {
            db.collection(`logs/${compId}/logsList`)
              .add({
                name: firebase.auth().currentUser.displayName,
                email: firebase.auth().currentUser.email,
                timestamp: new Date().getTime(),
                entity: "Survey Status",
                operation: "UPDATED",
                payload: { status: submit, title: title },
              })
              .then((res) => {
                dispatch(toggleSubmitSuccess());
              })
              .catch((err) => {
                alert(err);
              });
          })
          .catch((err) => dispatch(toggleSubmitFail(err)));
      })
      .catch((err) => dispatch(toggleSubmitFail(err)));
  };
};

//------------------------------------------------------------------------------------------------
export const addQuestion = (questionType, surveyType, number) => {
  let newQuestion = InitQuestions[questionType]();
  return {
    type:
      // (surveyType === actionTypes.COMPARATIVE &&
      actionTypes.ADD_UNGROUP_QUESTION,
    // actionTypes.ADD_QUESTION,
    payload: newQuestion,
    questionId: newQuestion._id,
    questionNo: number,
  };
};

export const activeQuestion = (questionId) => (dispatch) => {
  dispatch({
    type: actionTypes.ACTIVE_QUESTION,
    questionId,
  });
  if (questionId === "header") dispatch(switchTab(tabTypes.EDIT_SURVEY_TAB));
  else dispatch(switchTab(tabTypes.EDIT_QUESTION_TAB));
};

export const updateQuestion = (qid, params) => {
  return {
    type: actionTypes.UPDATE_QUESTION,
    questionId: qid,
    payload: params,
  };
};

export const updateSurveyHeader = (params) => {
  return {
    type: actionTypes.UPDATE_SURVEY_HEADER,
    payload: params,
  };
};

export const switchTab = (tab) => ({
  type: actionTypes.SWITCH_TAB,
  tab,
});

export const cloneQuestion = (question, questionId) => {
  return {
    type: actionTypes.CLONE_QUESTION,
    payload: {
      questionId: questionId,
      question: question,
      _id: newId(),
    },
  };
};
export const cloneGroupQuestion = (
  question,
  questionId,
  questInd,
  groupInd
) => {
  return {
    type: actionTypes.CLONE_GROUP_QUESTION,
    payload: {
      questionId: questionId,
      groupInd: groupInd,
      questInd: questInd,
      question: question,
      _id: newId(),
    },
  };
};
export const cloneUngroupQuestion = (question, questionId, questInd) => {
  return {
    type: actionTypes.CLONE_UNGROUP_QUESTION,
    payload: {
      questionId: questionId,
      questInd: questInd,
      question: question,
      _id: newId(),
    },
  };
};

//------------------------------------------------------------------------------------------

export const getSurveyId = (surveyId) => {
  return {
    type: actionTypes.GET_SURVEY_ID,
    surveyId: surveyId,
  };
};

export const getCompId = (compId) => {
  return {
    type: actionTypes.GET_COMPANY_ID,
    compId: compId,
  };
};

export const resetOpenTill = (surveyId) => {
  return {
    type: actionTypes.RESET_OPENTILL,
    surveyId: surveyId,
  };
};

export const openModal = () => {
  return {
    type: actionTypes.OPEN_MODAL_EDITER,
  };
};

export const closeModal = () => {
  return {
    type: actionTypes.CLOSE_MODAL_EDITER,
  };
};
