import React, { useRef, useState } from "react";
import { Field } from "formik";
import classes from "./Question.module.css";
import { FaStar } from "react-icons/fa";
import { MdOutlineRefresh } from "react-icons/md";
import {
  COMPARATIVE,
  PREVIEW_WRAPPER,
} from "../../../Store/actions/actionsTypes";
import ResizableTextarea from "../../../ulitity/ResizableTextarea";

const RatingStars = (props) => {
  const {
    title,
    stars,
    isRequired,
    _id,
    result,
    caller,
    surveyee_count,
    idAddOn,
  } = props;

  let start = result
    ? result[_id]
      ? typeof idAddOn == "number" && idAddOn >= 0
        ? result[_id][idAddOn]
        : result[_id]
      : 0
    : 0;
  const [rating, setRating] = useState(start);
  const [hover, setHover] = useState(0);
  let addOnId =
    typeof idAddOn == "number" && idAddOn >= 0 ? `[${idAddOn}]` : "";

  let defaultRemarksValue = result
    ? result[_id + "_remarks"]
      ? typeof idAddOn == "number" && idAddOn >= 0
        ? result[_id + "_remarks"][idAddOn]
        : result[_id + "_remarks"]
      : ""
    : "";
  return (
    <div className={classes.Question}>
      {caller === PREVIEW_WRAPPER && (
        <ResizableTextarea
          conditionalStyle={{
            color: isRequired ? "#e91e63" : "black",
          }}
          title={isRequired ? title + "*" : title}
          additionalClass={classes.Label}
        />
      )}
      {caller === PREVIEW_WRAPPER && surveyee_count && surveyee_count > 0 ? (
        ""
      ) : (
        <>
          <div>
            {[...Array(stars)].map((star, i) => {
              const ratingValue = i + 1;
              return (
                <label>
                  <Field
                    component="input"
                    type="radio"
                    name={`${_id}${addOnId}`}
                    value={ratingValue}
                    onClick={() => setRating(ratingValue)}
                    className={classes.Rating}
                  />
                  <FaStar
                    className={classes.Star}
                    color={
                      ratingValue <= (hover || rating) ? "#ffcc00" : "#cccccc"
                    }
                    size={35}
                    onMouseLeave={() => setHover(null)}
                    onMouseEnter={() => setHover(ratingValue)}
                  />
                </label>
              );
            })}
          </div>
          {props.otherOptionIncluded ? (
            <div>
              <Field
                type="text"
                placeholder={props.otherOptionValue}
                name={`${props._id}_remarks${addOnId}`}
                className={classes.Textarea}
                component="textarea"
                value={props.remarks}
                defaultValue={defaultRemarksValue}
              />
            </div>
          ) : (
            ""
          )}
          <div className={classes.resetIcon}>
            <label>
              <Field
                component="input"
                type="radio"
                name={_id}
                value={0}
                onClick={() => setRating(0)}
                className={classes.Rating}
              />
              <MdOutlineRefresh />
            </label>
          </div>
        </>
      )}
    </div>
  );
};

export default RatingStars;
