import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import classes from "./SurveyHeader.module.css";
import Button from "@material-ui/core/Button";
import lightGreen from "@material-ui/core/colors/lightGreen";
import { withStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import { COMPARATIVE } from "../../Store/actions/actionsTypes";
import ResizableTextarea from "../../ulitity/ResizableTextarea";

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(lightGreen["A400"]),
    backgroundColor: lightGreen["A400"],
    "&:hover": {
      backgroundColor: lightGreen["A700"],
    },
  },
}))(Button);

const SurveyHeader = (props) => {
  let {
    title,
    subTitle,
    onActive,
    onOpenModal,
    survey: { ratingIndex, ratingIndexAsFootnote },
  } = props;
  return (
    <header className={classes.SurveyHeader} onClick={onActive}>
      <h3>{title}</h3>
      <ResizableTextarea
        conditionalStyle={{
          fontSize: "1rem",
          fontWeight: 400,
          lineHeight: 1.5,
          textAlign: "left",
          color: "#333333",
        }}
        title={subTitle}
      />
      {!ratingIndexAsFootnote && ratingIndex ? (
        <>
          <h5>Rating Index - </h5>
          <ResizableTextarea
            conditionalStyle={{
              fontSize: "1rem",
              fontWeight: 400,
              lineHeight: 1.5,
              textAlign: "left",
              color: "#333333",
            }}
            title={ratingIndex}
          />
        </>
      ) : (
        ""
      )}

      <div className={classes.ForMobile}>
        <ColorButton
          variant="contained"
          size="small"
          color="primary"
          onClick={() => {
            onActive();
            onOpenModal();
          }}
        >
          <Icon>edit</Icon>
        </ColorButton>
      </div>
    </header>
  );
};

export default SurveyHeader;
