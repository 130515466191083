import React, { Component } from "react";
import ReportTable from "./ReportTable";
import RemarksReportTable from "./RemarksReport";
import classes from "./ReportItem.module.css";
import ResizableTextarea from "../../ulitity/ResizableTextarea";
class OnlyTableReportItem extends Component {
  state = {
    width: 500,
  };

  componentWillMount() {
    this.setState({ width: window.innerWidth });
  }

  render() {
    let { data } = this.props;
    return (
      <div className={classes.ReportItemWrapper}>
        <ResizableTextarea
          additionalClass={classes.Title}
          title={`Q. ${data.title}`}
        />
        <div key={data.title} className={classes.ReportItem}>
          <div className={classes.table}>
            <ReportTable data={data.stats} />
          </div>
          {data.optherOptionValue || data.optherOptionValue === "" ? (
            <div className={classes.table}>
              <RemarksReportTable
                data={data.remarks}
                columnName={data.optherOptionValue}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

OnlyTableReportItem.defaultProps = {};

export default OnlyTableReportItem;
