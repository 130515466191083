import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./TopMenuItems.module.css";
import { withRouter } from "react-router-dom";
import TopMenuItem from "./TopMenuItem/TopMenuItem";
import { COMPARATIVE } from "../../../../Store/actions/actionsTypes";
import Notiflix from "notiflix-react";
import { CHANGE_SURVEY_STATE } from "../../../../Store/actions/actionsTypes";
import { fetchSurvey } from "../../../../Store/actions/surveyEditer";

class TopMenuItems extends Component {
  constructor(props) {
    super(props);
    this.backBtnRef = React.createRef();
  }
  render() {
    if (this.props.role === "Admin") {
      return (
        <ul className={classes.TopMenuItems}>
          <button
            ref={this.backBtnRef}
            className={classes.Back}
            onClick={() => {
              if (this.props.isSurveySaved === true) {
                this.props.history.push({
                  pathname: "/" + this.props.compId + "/surveys",
                  state: { fromSurveys: true },
                });
              } else if (this.props.isSurveySaved === false) {
                Notiflix.Confirm.Init({
                  titleColor: "#32c6b2",
                  cancelButtonBackground: "#ea3434",
                  width: "360px",
                });
                Notiflix.Confirm.Show(
                  "Unsaved Changes",
                  "Are you sure you want to switch tab? Changes made to the survey will be lost.",
                  "Yes",
                  "No",
                  () => {
                    this.props.dispatchSurveyState(true);
                    this.backBtnRef.current.click();
                  },
                  () => {
                    this.props.dispatchSurveyState(false);
                  }
                );
              }
            }}
          >
            Back
          </button>

          <TopMenuItem
            isSurveySaved={this.props.isSurveySaved}
            isEditorTab={false}
            link={
              this.props.isSurveySaved
                ? "/user/" + this.props.surveyId + "/overview"
                : "/user/" + this.props.surveyId + "/editor"
            }
          >
            Survey Details
          </TopMenuItem>
          <TopMenuItem
            isSurveySaved={this.props.isSurveySaved}
            isEditorTab={true}
            link={"/user/" + this.props.surveyId + "/editor"}
          >
            Editor
          </TopMenuItem>
          <TopMenuItem
            surveyId={this.props.surveyId}
            compId={this.props.compId}
            fetchSurvey={this.props.fetchSurvey}
            isSurveySaved={this.props.isSurveySaved}
            isEditorTab={false}
            // isTestPage={true}
            link={
              this.props.isSurveySaved
                ? "/user/" + this.props.surveyId + "/test"
                : "/user/" + this.props.surveyId + "/editor"
            }
          >
            Test
          </TopMenuItem>
          <TopMenuItem
            surveyId={this.props.surveyId}
            compId={this.props.compId}
            fetchSurvey={this.props.fetchSurvey}
            isSurveySaved={this.props.isSurveySaved}
            isEditorTab={false}
            link={
              this.props.isSurveySaved
                ? "/user/" + this.props.surveyId + "/share"
                : "/user/" + this.props.surveyId + "/editor"
            }
          >
            Share Survey
          </TopMenuItem>
          <TopMenuItem
            surveyId={this.props.surveyId}
            compId={this.props.compId}
            fetchSurvey={this.props.fetchSurvey}
            isSurveySaved={this.props.isSurveySaved}
            isEditorTab={false}
            link={
              this.props.isSurveySaved
                ? "/user/" + this.props.surveyId + "/share-report"
                : "/user/" + this.props.surveyId + "/editor"
            }
          >
            Share Report
          </TopMenuItem>
          {/* <TopMenuItem link={"/user/" + this.props.surveyId + "/report"}>
            Report
          </TopMenuItem>
          <TopMenuItem link={"/user/" + this.props.surveyId + "/data"}>
            Data
          </TopMenuItem> */}
        </ul>
      );
    }

    if (this.props.role === "Director") {
      return (
        <ul className={classes.TopMenuItems}>
          <button
            className={classes.Back}
            onClick={() =>
              this.props.history.push({
                pathname: "/" + this.props.compId + "/surveys",
                state: { fromSurveys: true },
              })
            }
          >
            Back
          </button>
          <TopMenuItem
            role={this.props.role}
            link={"/user/" + this.props.surveyId + "/overview"}
          >
            Survey Details
          </TopMenuItem>
          {this.props.type !== COMPARATIVE && (
            <TopMenuItem
              role={this.props.role}
              link={"/user/" + this.props.surveyId + "/report"}
            >
              Report
            </TopMenuItem>
          )}
          {this.props.type !== COMPARATIVE && (
            <TopMenuItem
              role={this.props.role}
              link={"/user/" + this.props.surveyId + "/data"}
            >
              Data
            </TopMenuItem>
          )}
        </ul>
      );
    }

    if (this.props.role === "Auditor") {
      return (
        <ul className={classes.TopMenuItems}>
          <button
            className={classes.Back}
            onClick={() =>
              this.props.history.push({
                pathname: "/" + this.props.compId + "/surveys",
                state: { fromSurveys: true },
              })
            }
          >
            Back
          </button>
          <TopMenuItem
            role={this.props.role}
            link={"/user/" + this.props.surveyId + "/overview"}
          >
            Survey Details
          </TopMenuItem>
          <TopMenuItem
            role={this.props.role}
            link={"/user/" + this.props.surveyId + "/test"}
          >
            Survey Preview
          </TopMenuItem>
          <TopMenuItem
            role={this.props.role}
            link={"/user/" + this.props.surveyId + "/mailAudit"}
          >
            Mail Audit
          </TopMenuItem>
        </ul>
      );
    }

    if (this.props.role === "Editor") {
      return (
        <ul className={classes.TopMenuItems}>
          <button
            ref={this.backBtnRef}
            className={classes.Back}
            onClick={() => {
              if (this.props.isSurveySaved === true) {
                this.props.history.push({
                  pathname: "/" + this.props.compId + "/surveys",
                  state: { fromSurveys: true },
                });
              } else if (this.props.isSurveySaved === false) {
                Notiflix.Confirm.Init({
                  titleColor: "#32c6b2",
                  cancelButtonBackground: "#ea3434",
                  width: "360px",
                });
                Notiflix.Confirm.Show(
                  "Unsaved Changes",
                  "Are you sure you want to switch tab? Changes made to the survey will be lost.",
                  "Yes",
                  "No",
                  () => {
                    this.props.dispatchSurveyState(true);
                    this.backBtnRef.current.click();
                  },
                  () => {
                    this.props.dispatchSurveyState(false);
                  }
                );
              }
            }}
          >
            Back
          </button>
          <TopMenuItem
            isSurveySaved={this.props.isSurveySaved}
            isEditorTab={false}
            link={
              this.props.isSurveySaved
                ? "/user/" + this.props.surveyId + "/overview"
                : "/user/" + this.props.surveyId + "/editor"
            }
          >
            Survey Details
          </TopMenuItem>
          <TopMenuItem
            isSurveySaved={this.props.isSurveySaved}
            isEditorTab={true}
            link={"/user/" + this.props.surveyId + "/editor"}
          >
            Editor
          </TopMenuItem>
          <TopMenuItem
            surveyId={this.props.surveyId}
            compId={this.props.compId}
            isSurveySaved={this.props.isSurveySaved}
            fetchSurvey={this.props.fetchSurvey}
            isEditorTab={false}
            isTestPage={true}
            link={"/user/" + this.props.surveyId + "/test"}
          >
            Test
          </TopMenuItem>
          <TopMenuItem
            surveyId={this.props.surveyId}
            compId={this.props.compId}
            isSurveySaved={this.props.isSurveySaved}
            fetchSurvey={this.props.fetchSurvey}
            isEditorTab={false}
            link={
              this.props.isSurveySaved
                ? "/user/" + this.props.surveyId + "/share-report"
                : "/user/" + this.props.surveyId + "/editor"
            }
          >
            Share Report
          </TopMenuItem>
        </ul>
      );
    }

    return <h4>You do not have access to this page</h4>;
  }
}

const mapStateToProps = (state) => {
  return {
    isSurveySaved: state.surveyEditer.survey.survey_saved,
    type: state.surveyEditer.survey.type,
    surveyId: state.surveyEditer.survey.id,
    role: state.surveyEditer.company.role,
    compId: state.surveyEditer.company.id,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchSurvey: (surveyId, token, compId) => {
      dispatch(fetchSurvey(surveyId, token, compId));
    },
    dispatchSurveyState: (state) => {
      dispatch({ type: CHANGE_SURVEY_STATE, payload: state });
    },
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TopMenuItems)
);
