import firebase from "firebase";
import React, { Component } from "react";
import classes from "./SharePage.module.css";
import { connect } from "react-redux";
import * as actionTypes from "../Store/actions/actionsTypes";
import { withRouter } from "react-router-dom";
import Notiflix from "notiflix-react";
import "react-multi-email/style.css";
import InviteeList from "../Containers/InviteeList/InviteeList";
import GreenSwitcher from "../Components/UI/Switcher/GreenSwitch";
import Avatar from "@material-ui/core/Avatar";
import Alert from "@material-ui/lab/Alert";
import Popover from "react-bootstrap/Popover";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import newId from "../ulitity/idGenerator";
import * as async from "async";
import EmailGroups from "../Containers/EmailGroups/EmailGroups";
import _ from "lodash";
import http from "../lib/http-service";
import { db } from "../firebase-config";
const monthNameArray = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
class SharePage extends Component {
  componentDidMount() {
    firebase.analytics().logEvent("Survey Share page is rendered");
    this.getPendingInviteEmails();
  }

  state = {
    email: {
      subject: `${this.props.compname} - ${this.props.survname}`,
      dest: "",
      message: `Please find the link for the ${
        this.props.survname
      } for the year ${new Date().getFullYear()}. Request you to fill up this survey before ${new Date(
        this.props.openTill.split("T")[0]
      ).getDate()} ${
        monthNameArray[new Date(this.props.openTill.split("T")[0]).getMonth()]
      }, ${new Date(this.props.openTill.split("T")[0]).getFullYear()} ${
        new Date(this.props.openTill).toLocaleTimeString("en-US").slice(0, 5) +
        new Date(this.props.openTill).toLocaleTimeString("en-US").slice(8)
      } .For any assistance please contact ${firebase.auth().currentUser.email}

Thank you for your time.
Yours sincerely,
${firebase.auth().currentUser.displayName}
      `,
      token: "",
    },
    copied: false,
    emails: [],
    bind: false,
    attachments: [],
    attachment: {},
    menuItem: "EMAIL_GROUPS",
    activeEmailGroupId: "",
  };

  sendEmail = () => {
    const { email } = this.state;
    let formattedMessage = email.message.replace(/\n/g, "<br>");
    firebase
      .auth()
      .currentUser.getIdToken()
      .then((idToken) => {
        const emarr = this.state.emails;
        const mailAudit = {
          _id: newId(),
          recipients: emarr,
          subject: email.subject,
          body: formattedMessage,
          time: new Date().getTime(),
          type: "SURVEY",
          targetId: this.props.surveyId,
          attachments: this.state.attachments,
          mailSender: firebase.auth().currentUser.email,
        };
        const listIdentityReq = {
          email: firebase.auth().currentUser.email,
        };
        Notiflix.Loading.Init({ svgColor: "#3bbfde" });
        Notiflix.Loading.Pulse("Sending Mail");
        // Checking if sender email id is verified or not.
        http.post(`/listSESIdentities`, listIdentityReq).then((response) => {
          if (response.status === 200) {
            // Sender Email Id is alrady verified and proceed with the Email Send operation

            Notiflix.Loading.Init({ svgColor: "#3bbfde" });
            Notiflix.Loading.Pulse("Sending Mail");

            db.collection(`mailAudit/${this.props.surveyId}/mailAuditList`)
              .doc(String(mailAudit._id))
              .set(mailAudit)
              .then(() => {
                const fetcharr = [];
                for (var em in emarr) {
                  Notiflix.Loading.Init({ svgColor: "#3bbfde" });
                  Notiflix.Loading.Pulse("Sending Mail");
                  var reqBody = {
                    dest: emarr[em],
                    subject: email.subject,
                    surveyid: this.props.surveyId,
                    compid: this.props.compId,
                    bind: this.state.bind,
                    mailAuditId: mailAudit._id,
                    compName: this.props.compname,
                    fromEmail: firebase.auth().currentUser.email,
                  };
                  fetcharr.push(http.post(`/sendMailInvite`, reqBody));
                }
                Promise.all(fetcharr)
                  .then((res) => {
                    if (res[0].status === 200) {
                      db.collection(`logs/${this.props.compId}/logsList`)
                        .add({
                          name: firebase.auth().currentUser.displayName,
                          email: firebase.auth().currentUser.email,
                          timestamp: new Date().getTime(),
                          entity: "Survey Invite",
                          operation: "SENT",
                          payload: {
                            email: this.state.emails.join(", "),
                            survey: this.props.survname,
                          },
                        })
                        .then((res) => {
                          this.setState({
                            email: {
                              ...email,
                              subject: `${this.props.compname} - ${this.props.survname}`,
                              dest: "",
                              token: "",
                            },
                          });
                          Notiflix.Loading.Remove();
                          Notiflix.Report.Init({
                            width: "400px",
                            titleFontSize: "20px",
                            messageFontSize: "17px",
                            success: { buttonBackground: "#34baf2" },
                          });
                          Notiflix.Report.Success(
                            "Email Invite Successful",
                            "Email Invitation Has Been Successfully Sent To " +
                              this.state.emails.join(", "),
                            "ok"
                          );
                          this.setState({ emails: [] });
                          this.setState({ attachments: [] });
                          this.handleEmailGroup("");
                        })
                        .catch((err) => {
                          Notiflix.Report.Init({
                            width: "400px",
                            titleFontSize: "20px",
                            messageFontSize: "17px",
                            success: { buttonBackground: "#34baf2" },
                          });
                          Notiflix.Report.Failure(
                            "Email Invite Successful but email could not be saved",
                            "Invite was successfully sent but the email could not be saved, this is an unexpecrted error",
                            "ok"
                          );
                          this.setState({ emails: [] });
                          this.setState({ attachments: [] });
                        });
                    }
                  })
                  .catch((err) => {
                    Notiflix.Loading.Remove();
                    Notiflix.Report.Init({
                      width: "400px",
                      titleFontSize: "20px",
                      messageFontSize: "17px",
                      success: { buttonBackground: "#EE3A2C" },
                    });
                    Notiflix.Report.Failure(
                      "Email Invite Unsucessful",
                      "Email Invitation Could Not Be Sent!",
                      "ok"
                    );
                    this.setState({ emails: [] });
                    this.setState({ attachments: [] });
                  });
              })
              .catch((error) => {
                alert(error);
              });
          } else {
            // Sender Email Id is not verified. Sedning the Verification link to verify the email from AWS
            Notiflix.Loading.Init({ svgColor: "#3bbfde" });
            Notiflix.Loading.Pulse("Sending Mail");
            http.post(`/verifyEmail`, listIdentityReq).then((response) => {
              if (response.status === 200) {
                Notiflix.Loading.Remove();
                Notiflix.Report.Init({
                  width: "400px",
                  titleFontSize: "20px",
                  messageFontSize: "17px",
                  success: { buttonBackground: "#EE3A2C" },
                });
                Notiflix.Report.Failure(
                  "Email Not Sent",
                  "Verification of Email is pending. Verification link has been sent to your Email id. Please verify and then try sending survey",
                  "ok"
                );
                this.setState({ emails: [] });
              } else {
                Notiflix.Report.Failure(
                  "Some error happened",
                  "Please contact Administrator ..!",
                  "ok"
                );
              }
            });
          }
        });

        // const ref = storage.child('uploads/Varedima_Co_Board_Meeting_Agenda_10-Apr-2020.pdf');
      });
  };

  // Function to upload files to storage
  uploadFile = (files) => {
    Notiflix.Loading.Init({ svgColor: "#3bbfde" });
    Notiflix.Loading.Pulse("Uploading Files");

    const { attachments } = this.state;
    const storage = firebase.storage().ref();
    const filePath =
      "uploads/" +
      this.props.compId +
      "/" +
      this.props.surveyId +
      "/" +
      new Date().getTime() +
      "/";

    let totalUploadedSize = 0;
    if (attachments.length > 0) {
      attachments.forEach((fileData) => {
        totalUploadedSize += fileData.size;
      });
    }

    if (files.length > 0) {
      for (var i = 0; i < files.length; i++) {
        totalUploadedSize += files[i]["size"];
      }
    }

    let totalSize = Math.ceil(totalUploadedSize / 3) * 4;

    if (totalSize > 9000000) {
      Notiflix.Loading.Remove();
      Notiflix.Report.Init({
        width: "400px",
        titleFontSize: "20px",
        messageFontSize: "17px",
        success: { buttonBackground: "#EE3A2C" },
      });
      Notiflix.Report.Failure(
        "You have exceeded maximum limit",
        "More than 10MB "
      );
      document.getElementById("mailFiles").value = null;
      return false;
    }

    async.eachSeries(
      files,
      (singleFile, uploadCb) => {
        const storageRef = storage.child(filePath + singleFile.name);
        storageRef.put(singleFile).then((snapshot) => {
          const ref = storage.child(snapshot.metadata.fullPath);
          ref
            .getDownloadURL()
            .then((url) => {
              const fileUploaded = {
                name: snapshot.metadata.name,
                size: snapshot.metadata.size,
                link: url,
                type: snapshot.metadata.contentType,
                filePath: filePath + singleFile.name,
              };
              attachments.push(fileUploaded);
              uploadCb();
            })
            .catch((error) => {
              uploadCb(error);
            });
        });
      },
      (asyncLoopError) => {
        if (asyncLoopError) {
          console.log(
            "Error while batch upload files. Error => ",
            asyncLoopError
          );
        } else {
          Notiflix.Loading.Remove();
          this.setState({ attachments: attachments });
          document.getElementById("mailFiles").value = null;
        }
      }
    );
  };

  // Function to remove the attachment
  removeFile = (index) => {
    Notiflix.Loading.Init({ svgColor: "#3bbfde" });
    Notiflix.Loading.Pulse("Deleting File");
    const { attachments } = this.state;
    const storage = firebase.storage().ref();
    var desertRef = storage.child(attachments[index].filePath);
    desertRef
      .delete()
      .then((success) => {
        attachments.splice(index, 1);
        this.setState({ attachments: attachments });
        Notiflix.Loading.Remove();
      })
      .catch((error) => {
        Notiflix.Loading.Remove();
        Notiflix.Report.Init({
          width: "400px",
          titleFontSize: "20px",
          messageFontSize: "17px",
          success: { buttonBackground: "#EE3A2C" },
        });
        Notiflix.Report.Failure("File deletion failed");
      });
  };

  // Function to download the file
  downloadFile = (link) => {
    window.open(link);
  };

  // Toggle between history and share
  switchMenu = (menu) => {
    this.setState({ menuItem: menu });
  };

  handleEmailGroup = (emailGroupId) => {
    const filterEmailGroup = this.props.emailGroups.filter(
      (emailGroup) => emailGroup.id === emailGroupId
    );

    this.setState({
      activeEmailGroupId: emailGroupId,
      emails: filterEmailGroup[0]?.emails || [],
    });
  };

  getPendingInviteEmails = () => {
    db.collection("temp")
      .where("surveyId", "==", this.props.surveyId)
      .onSnapshot((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => data.push({ ...doc.data() }));
        if (data.length) {
          const pendingInvites = data.filter((el) => el.status == "Incomplete");

          if (pendingInvites.length > 0) {
            const emails = _.map(pendingInvites, "emailId");
            this.props.setPendingInvites({
              name: "Pending Responders",
              emails: emails,
              id: "1",
            });
          }
        }
      });
  };

  render() {
    const { email } = this.state;
    const { menuItem } = this.state;
    const spacer = {
      margin: 10,
    };
    const textArea = {
      borderRadius: 4,
    };

    let allEmailGroups = this.props.emailGroups;
    const seen = new Set();
    let updatedEmailGroups = [];
    if (allEmailGroups.length > 0) {
      updatedEmailGroups = allEmailGroups.filter((el) => {
        const duplicateGroup = seen.has(el.id);
        seen.add(el.id);
        return !duplicateGroup;
      });
    }

    const popover = (
      <Popover id="popover-basic">
        <Popover.Title as="h3">Bind Emails</Popover.Title>
        <Popover.Content>
          When this option is enabled, the user can only perform the survey when
          they have logged in with the same account to which the email is sent.
        </Popover.Content>
      </Popover>
    );
    return (
      <div className={classes.ShareForm}>
        <div className={classes.ShareComponent}>
          <div style={{ marginTop: 10 }}>
            <label
              className={
                classes.TopMenu +
                " " +
                (menuItem === "EMAIL_GROUPS" ? classes.UNDERLINETEXT : "")
              }
              onClick={() => this.switchMenu("EMAIL_GROUPS")}
            >
              View/Add Group
            </label>
            <label
              className={
                classes.TopMenu +
                " " +
                classes.History +
                " " +
                (menuItem === "SENDMAIL" ? classes.UNDERLINETEXT : "")
              }
              onClick={() => this.switchMenu("SENDMAIL")}
            >
              {" "}
              Share By Email{" "}
            </label>
            <label
              className={
                classes.TopMenu +
                " " +
                classes.History +
                " " +
                (menuItem === "HISTORY" ? classes.UNDERLINETEXT : "")
              }
              onClick={() => {
                this.switchMenu("HISTORY");
              }}
            >
              Mail Audit
            </label>
          </div>
          {/* Send Mail Start*/}
          {menuItem === "SENDMAIL" && (
            <div style={{ marginTop: 10 }}>
              <div
                style={{
                  display: this.props.status === "Inactive" ? null : "none",
                  marginTop: "20px",
                  width: "80vw",
                }}
              >
                <Alert severity="error">
                  This company is currently inactive, email invites cannot be
                  sent.
                </Alert>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "30px",
                  marginBottom: "20px",
                  backgroundColor: "#F6F7F9",
                  padding: "15px",
                  borderRadius: "5px",
                  width: "80vw",
                }}
              >
                <GreenSwitcher
                  checked={this.state.bind}
                  onChange={() => {
                    if (this.state.bind === true) {
                      this.setState({ bind: false });
                    } else {
                      this.setState({ bind: true });
                    }
                  }}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                <h6 style={{ marginTop: "10px", fontSize: "18px" }}>
                  Bind Link To Email
                </h6>
                <OverlayTrigger placement="right" overlay={popover}>
                  <div>
                    <Avatar
                      style={{
                        height: "22px",
                        width: "22px",
                        backgroundColor: "#34baf2",
                        marginTop: "9px",
                        marginLeft: "15px",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faQuestion}
                        style={{
                          fontSize: "15px",
                          color: "white",
                        }}
                      />
                    </Avatar>
                  </div>
                </OverlayTrigger>
              </div>
              <Alert
                severity="warning"
                style={{
                  marginTop: "15px",
                  marginBottom: "30px",
                  display: this.state.bind === true ? null : "none",
                  width: "80vw",
                }}
              >
                Enabling bind will require the respondents to perform an extra
                step of logging in before taking the survey.
              </Alert>
              <div style={{ display: "flex" }}>
                {updatedEmailGroups.length > 0 && (
                  <div>
                    <label> Group Name </label>
                    <br />
                    <select
                      className={classes.SelectEmailGroup}
                      value={this.state.activeEmailGroupId}
                      onChange={(e) => this.handleEmailGroup(e.target.value)}
                    >
                      <option value={""}>Select</option>
                      {updatedEmailGroups.map((emailGroup) => (
                        <option value={emailGroup.id}>{emailGroup.name}</option>
                      ))}
                    </select>
                  </div>
                )}
                <div>
                  <label>
                    Recipients (Press Space/Enter after each email){" "}
                  </label>

                  <br />
                  <ReactMultiEmail
                    placeholder="Enter Recipients"
                    style={{
                      width: "67vw",
                      border: "2px solid #34baf2",
                      borderRadius: "3px",
                      minWidth: "200px",
                      minHeight: "30px",
                      marginTop: "2px",
                      marginBottom: "15px",
                      fontSize: "20px",
                      padding: "7px",
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                    emails={this.state.emails}
                    onChange={(_emails) => {
                      this.setState({ emails: _emails });
                    }}
                    validateEmail={(email) => {
                      return isEmail(email); // return boolean
                    }}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div data-tag key={index}>
                          <p
                            style={{
                              fontSize: "17px",
                              marginTop: "13px",
                              color: "#000",
                            }}
                          >
                            {email}
                          </p>
                          <span
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                            style={{ fontSize: "20px" }}
                          >
                            ×
                          </span>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>

              <div style={spacer} />
              <label> Subject </label>
              <br />
              <input
                className={classes.Subject}
                value={email.subject}
                onChange={(e) =>
                  this.setState({
                    email: { ...email, subject: e.target.value },
                  })
                }
              />
              <div style={spacer} />
              <label> Attachment </label>
              <br />
              <input
                type="file"
                id="mailFiles"
                className={classes.FileUpload}
                name="attachment"
                multiple
                onChange={(e) => {
                  this.uploadFile(e.target.files);
                }}
              />

              <div style={spacer} />
              {this.state.attachments.map((file, i) => (
                <div>
                  {" "}
                  <FontAwesomeIcon
                    icon={faPaperclip}
                    style={{ color: "#BFC3C5" }}
                  ></FontAwesomeIcon>
                  <label
                    className={classes.FileItem}
                    onClick={() => this.downloadFile(file.link)}
                  >
                    {" "}
                    {file.name}{" "}
                  </label>
                  <span
                    className={classes.DeleteFile}
                    onClick={() => this.removeFile(i)}
                  >
                    {" "}
                    x{" "}
                  </span>{" "}
                </div>
              ))}

              <div style={spacer} />
              <label> Message </label>
              <br />
              <textarea
                className={classes.Message}
                rows={3}
                value={email.message}
                style={textArea}
                onChange={(e) =>
                  this.setState({
                    email: { ...email, message: e.target.value },
                  })
                }
              />
              <div style={spacer} />
              <button
                onClick={() => {
                  if (this.props.status === "Inactive") {
                    alert("Company Inactive");
                  } else if (this.state.emails.length < 1) {
                    Notiflix.Report.Init({
                      width: "400px",
                      titleFontSize: "20px",
                      messageFontSize: "17px",
                      success: { buttonBackground: "#EE3A2C" },
                    });
                    Notiflix.Report.Failure(
                      "No Recipients!",
                      "Add Recipients to send survey.",
                      "ok"
                    );
                  } else {
                    let emailarr = this.state.emails;
                    this.sendEmail();
                  }
                }}
                className={classes.SendButton}
                disabled={this.props.status === "Inactive"}
                style={{
                  display: this.props.status === "Inactive" ? "none" : null,
                }}
              >
                Send Email
              </button>
            </div>
          )}
          {/* Send Mail Ends*/}

          {/* History Items Start*/}
          {menuItem === "HISTORY" && (
            <div style={{ marginTop: 10 }}>
              <InviteeList />
            </div>
          )}
          {/* History Items Ends*/}

          {menuItem === "EMAIL_GROUPS" && (
            <div style={{ marginTop: 10 }}>
              <EmailGroups />
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user_Id: state.auth.userId,
    token: state.auth.token,
    surveyId: state.surveyEditer.survey.id,
    compId: state.surveyEditer.company.id,
    compname: state.surveyEditer.company.name,
    emailGroups: state.surveyEditer.company.emailGroups,
    survname: state.surveyEditer.survey.title,
    status: state.surveyEditer.company.status,
    openTill: state.surveyEditer.survey.openTill,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetSurveyId: (surveyId) =>
      dispatch({ type: actionTypes.GET_SURVEY_ID, surveyId: surveyId }),
    setPendingInvites: (pendingInvites) =>
      dispatch({ type: "SET_PENDING_INVITES", pendingInvites }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SharePage)
);
