import React, { useRef } from "react";
import { NavLink } from "react-router-dom";

import classes from "./TopMenuItem.module.css";
import Notiflix from "notiflix-react";
import { useDispatch } from "react-redux";
import { CHANGE_SURVEY_STATE } from "../../../../../Store/actions/actionsTypes";

const TopMenuItem = (props) => {
  let dispatch = useDispatch();
  const reference = useRef(null);
  const checkSurveyStatus = (e) => {
    if (
      props?.isSurveySaved === false &&
      props?.isEditorTab === false &&
      props.isTestPage !== true
    ) {
      // e.stopPropagation();
      Notiflix.Confirm.Init({
        titleColor: "#32c6b2",
        cancelButtonBackground: "#ea3434",
        width: "360px",
      });
      Notiflix.Confirm.Show(
        "Unsaved Changes",
        "Are you sure you want to switch tab? Changes made to the survey will be lost.",
        "Yes",
        "No",
        function () {
          dispatch({ type: CHANGE_SURVEY_STATE, payload: true });
          props.fetchSurvey(props.surveyId, "", props.compId);
          reference.current.click();
        },
        function () {
          dispatch({ type: CHANGE_SURVEY_STATE, payload: false });
        }
      );
    }
  };
  return (
    <li onClick={checkSurveyStatus} className={classes.TopMenuItem}>
      <NavLink
        ref={reference}
        to={props.link}
        activeClassName={
          props.isTestPage === true
            ? classes.active
            : props.role === ("Director" || "Auditor")
            ? classes.active
            : props?.isSurveySaved !== true && props?.isEditorTab === true
            ? classes.active
            : props?.isSurveySaved === true
            ? classes.active
            : ""
        }
      >
        {props.children}
      </NavLink>
    </li>
  );
};

export default TopMenuItem;
