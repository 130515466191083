import firebase from "firebase";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import classes from "./UserSurveyPage.module.css";
import { assembleSurvey } from "../Store/actions/surveyEditer";
import {
  assembleRegularSubmitdata,
  fetchUserSurvey,
  submitSurvey,
  assembleComparativeSubmitData,
  mergeDraftValues,
} from "../Store/actions/survey";
import Survey from "../Components/Survey/Survey";
import { Alert, AlertTitle } from "@material-ui/lab";
import moment from "moment";
import Notiflix from "notiflix-react";
import { isEmty, onLoginClickFromUserSurvey } from "../ulitity/ulitity";
import newId from "../ulitity/idGenerator";
import http from "../lib/http-service";
import { db } from "../firebase-config";
import { COMPARATIVE } from "../Store/actions/actionsTypes";

class UserSurveyPage extends Component {
  state = {
    logo: "",
    name: "",
    cin: "",
    loading: "",
    loggedin: "",
    invalid: "",
    unauth: "",
    err: "",
    surveyingId: "",
    complete: "",
    user: "",
    expired: "",
    result: "",
  };

  componentDidMount() {
    this.setState({ loading: "true" });
    Notiflix.Loading.Init({ svgColor: "#3bbfde" });
    Notiflix.Loading.Pulse("Verifying Link. Please Wait");
    let email = "null";
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        email = user.email;
      }
      http
        .get(
          "/authenticateSurvey?idval=" +
            this.props.match.url.split("/")[2] +
            "&hash=" +
            this.props.match.url.split("/")[1] +
            "&email=" +
            email
        )
        .then((res) => {
          const { data } = res;
          if (data.response === "success") {
            firebase.auth().onAuthStateChanged((user) => {
              if (user) {
                const compp = this;

                db.collection(`surveys/${data.compId}/surveysList`)
                  .doc(String(data.survId))
                  .get()
                  .then((doc) => {
                    if (!doc.exists) return;

                    const surveyData = { ...doc.data() };
                    const contentData = surveyData?.content;
                    if (contentData) {
                      if (
                        moment(contentData["openTill"]).format("x") -
                          moment(new Date().toISOString()).format("x") >
                        0
                      ) {
                        console.log("survey is valid.");
                        compp.setState({
                          loading: "false",
                          surveyingId: data.survId,
                          result: data.result || { result: {} },
                        });
                        compp.props.onFetch(data.survId, data.compId);
                        Notiflix.Loading.Remove();
                      } else {
                        console.log("survey is invalid.");
                        compp.setState({ expired: true });
                        Notiflix.Loading.Remove();
                      }
                    }
                  });
              } else {
                firebase.auth().signInAnonymously();
                console.log("signed in Anonymously");
              }
            });
          } else if (data.response === "Invalid Link") {
            this.setState({ invalid: true });
            Notiflix.Loading.Remove();
          } else if (data.response === "Unauthorized Request") {
            this.setState({ unauth: true });
            Notiflix.Loading.Remove();
          } else if (data.response === "Error Handling Request") {
            this.setState({ err: true });
            Notiflix.Loading.Remove();
          } else if (data.response === "Survey Completed") {
            this.setState({ complete: true });
            Notiflix.Report.Init({
              width: "400px",
              titleFontSize: "20px",
              messageFontSize: "17px",
              success: { buttonBackground: "#EE3A2C" },
            });
            Notiflix.Report.Success(
              "Survey Completed",
              "You have already completed this survey and your response has been recorded.Please contact the survey owner if you think this is an error.",
              "ok"
            );
            Notiflix.Loading.Remove();
          } else if (data.response === "Invalid User") {
            this.setState({ loggedin: false });
            Notiflix.Loading.Remove();
          }
          if (data.compId) {
            var comp = this;
            firebase.auth().onAuthStateChanged((user) => {
              if (user) {
                db.collection(`surveys/${data.compId}/surveysList`)
                  .doc(String(data.survId))
                  .get()
                  .then((doc) => {
                    if (!doc.exists) return;

                    const surveyData = { ...doc.data() };
                    const contentData = surveyData?.content;
                    if (contentData) {
                      comp.setState({
                        logo: data.logo,
                        name: data.name,
                        cin: data.cin,
                        opentill:
                          contentData.openTill.split("T")[0] +
                          " " +
                          contentData.openTill.split("T")[1],
                      });
                      Notiflix.Loading.Remove();
                    }
                  });
              }
            });
          }
        });
    });
  }

  getCompanyId = (url) => {
    return url.split("/")[1];
  };

  componentDidUpdate(prevProps) {
    if (this.props.match.url !== prevProps.match.url) {
      this.props.onFetch(this.getSurveyId(this.props.match.url));
    }
  }

  getUserId = (url) => {
    return url.split("/")[4];
  };

  getSurveyId = (url) => {
    return url.split("/")[2];
  };

  saveSurvey = (results, values, type) => {
    let result = {};
    let finalValues;

    if (type !== COMPARATIVE) {
      // finalValues = { ...results, ...values };
      finalValues = { ...mergeDraftValues(results, values, type) };
      finalValues = { ...assembleRegularSubmitdata(finalValues) };
    } else if (type === COMPARATIVE) {
      // finalValues = { ...results, ...values };
      finalValues = { ...mergeDraftValues(results, values, type) };
      finalValues = { ...assembleComparativeSubmitData(finalValues) };
    }
    if (!isEmty(finalValues)) {
      result = {
        result: { ...finalValues },
        surveyId: this.state.surveyingId,
        savedDate: new Date().toString(),
      };
    }
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        db.collection("temp")
          .doc(String(this.props.match.url.split("/")[1]))
          .update({
            result: result,
          })
          .then(() => {
            Notiflix.Notify.Init({ position: "left-bottom" });
            Notiflix.Notify.Success("Draft Saved Successfully");
          })
          .catch((err) => {
            console.log("Error while saving data -->", err);
            Notiflix.Notify.Init({ position: "left-bottom" });
            Notiflix.Notify.Failure(
              "Data could not be saved, DO NOT exit this page"
            );
          });
      }
    });
  };

  render() {
    return (
      <div>
        <div
          className={classes.companyHeader}
          style={{
            display: this.state.logo === "" ? "none" : null,
          }}
        >
          <div className={classes.compinfo}>
            <img src={this.state.logo} className={classes.logo} alt=""></img>

            <h5 className={classes.Name}>{this.state.name}</h5>
          </div>
          <div className={classes.cin}>
            <h5 className={classes.cinnum}>Open Till: &nbsp;</h5>
            <h5 className={classes.cinnum}>
              {" " +
                moment(this.state.opentill).format("DD/MM/YYYY [at] h:mm A")}
            </h5>
          </div>
        </div>
        <div
          style={{
            display: this.state.loading === "true" ? "none" : null,
            backgroundColor: "#F6F6F6",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <Survey
            {...this.props}
            surveyId={this.state.surveyingId}
            saveSurvey={this.saveSurvey}
            result={this.state.result}
          />
        </div>
        <div
          className={classes.errormain}
          style={{ display: this.state.loggedin === false ? null : "none" }}
        >
          <Alert
            severity="error"
            variant="filled"
            style={{ fontSize: "18px", margin: "30px" }}
          >
            This survey is marked private by the assigner, please login with the
            same account to which the survey invite was originally sent.You can
            use the below button to login after which you will be redirected
            back here.
          </Alert>
          <button
            className={classes.LoginToContinue}
            style={{ border: "none", marginTop: "20px" }}
            onClick={redirect}
          >
            Login To Continue
          </button>
        </div>
        <div
          className={classes.errormain}
          style={{
            display:
              this.state.invalid === true || this.state.unauth === true
                ? null
                : "none",
          }}
        >
          <Alert
            severity="error"
            variant="filled"
            style={{ fontSize: "18px", margin: "30px" }}
          >
            The survey you are trying to access does not exist,it may have been
            deleted, please recheck the link.
          </Alert>
        </div>
        <div
          className={classes.errormain}
          style={{ display: this.state.expired === true ? null : "none" }}
        >
          <Alert
            severity="warning"
            variant="filled"
            style={{ fontSize: "18px", margin: "30px" }}
          >
            This survey has expired
          </Alert>
        </div>
        <div
          className={classes.errormain}
          style={{ display: this.state.err === true ? null : "none" }}
        >
          <Alert
            severity="error"
            variant="filled"
            style={{ fontSize: "18px", margin: "30px" }}
          >
            An unexpected error has ocurred, please refresh the page to try
            again.
          </Alert>
        </div>
        <div
          className={classes.errormain}
          style={{ display: this.state.complete === true ? null : "none" }}
        >
          <Alert
            severity="success"
            variant="filled"
            style={{ fontSize: "18px", margin: "30px" }}
          >
            Thank you for taking this survey, your response is submitted
            successfully.
          </Alert>
        </div>
        <div
          className={classes.errormain}
          style={{ display: this.state.user === true ? null : "none" }}
        >
          <Alert
            severity="error"
            variant="filled"
            style={{ fontSize: "18px", margin: "30px" }}
          >
            This survey is marked private by the assigner, please login with the
            same account to which the survey invite was originally sent.You can
            use the below button to login after which you will be redirected
            back here.
          </Alert>
        </div>
      </div>
    );
  }
}
function redirect() {
  onLoginClickFromUserSurvey();
  window.location.href =
    window.location.origin + "/login?redirectto=" + window.location.href;
}

const mapStatetoProps = (state) => {
  return {
    survey: assembleSurvey(state.survey.survey),
    question_required: state.survey.survey.question_required,
    type: state.survey.survey.type,
    isRequired: state.survey.isRequired,
    isLoading: state.survey.isFetching || state.survey.isSubmitting,
    isSuccess: state.survey.isSuccess,
    isError: state.survey.error !== null,
    token: state.auth.token,
    AuthSubmit: state.survey.survey.submitting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: bindActionCreators(submitSurvey, dispatch),
    onFetch: (surveyId, compId) => dispatch(fetchUserSurvey(surveyId, compId)),
  };
};

export default connect(mapStatetoProps, mapDispatchToProps)(UserSurveyPage);
