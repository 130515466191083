import React, { Component } from "react";
import ReportGraph from "./ReportGraph";
import ReportTable from "./ReportTable";
import ReportPie from "./ReportPie";
import RemarksReportTable from "./RemarksReport";
import classes from "./ReportItem.module.css";
import ResizableTextarea from "../../ulitity/ResizableTextarea";
class ReportItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 500,
    };
  }

  componentWillMount() {
    this.setState({ width: window.innerWidth });
  }

  render() {
    let { data } = this.props;
    return (
      <div className={classes.ReportItemWrapper}>
        {/* <h3 className={classes.Title}>{`Q. ${data.title}`}</h3> */}
        <ResizableTextarea
          title={`Q. ${data.title}`}
          additionalClass={classes.Title}
        />
        <div key={data.title} className={classes.ReportItem}>
          <div className={classes.Graph}>
            <ReportGraph data={data.stats} width={this.state.width} />
          </div>
          <div className={classes.pie}>
            <ReportPie data={data.stats} />
          </div>
          <div className={classes.table}>
            <ReportTable data={data.stats} />
          </div>
          {(data.optherOptionValue || data.optherOptionValue === "") &&
          data?.remarks.length > 0 ? (
            <div className={classes.table}>
              <RemarksReportTable
                data={data.remarks}
                columnName={data.optherOptionValue}
              />
            </div>
          ) : (
            ""
          )}
          {data.averageRating ? (
            <h3>
              Average Rating is:{" "}
              {isNaN(data.averageRating) ? "-" : data.averageRating}
            </h3>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

ReportItem.defaultProps = {};

export default ReportItem;
