import React from "react";
import {
  COMPARATIVE,
  PREVIEW_WRAPPER,
} from "../../../Store/actions/actionsTypes";
import ResizableTextarea from "../../../ulitity/ResizableTextarea";

import classes from "./QuestionPreview.module.css";

const Multichoice = (props) => {
  const {
    title,
    options,
    _id,
    isRequired,
    caller,
    surveyee_count,
    otherOptionIncluded,
    otherOptionValue,
  } = props;

  return (
    <>
      {caller === PREVIEW_WRAPPER && (
        <ResizableTextarea
          conditionalStyle={{
            color: isRequired ? "#e91e63" : "black",
          }}
          title={isRequired ? title + "*" : title}
          additionalClass={classes.Label}
        />
      )}
      <div>
        {caller === PREVIEW_WRAPPER && surveyee_count && surveyee_count > 0
          ? ""
          : options.map((option, index) => {
              return (
                <div key={index} className="radio">
                  <label>
                    <input
                      type="radio"
                      name={_id}
                      value={option._id}
                      disabled
                    />
                    {option.content}
                  </label>
                </div>
              );
            })}
      </div>
      {otherOptionIncluded && (
        <textarea
          type="text"
          placeholder={otherOptionValue}
          name={_id}
          className={classes.Textarea}
          disabled
        />
      )}
    </>
  );
};

export default Multichoice;
