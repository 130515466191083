import React, { Component } from "react";
import { Formik, withFormik } from "formik";
import QuestionWrapper from "./QuestionWrapper";
import Button from "@material-ui/core/Button";
import Spinner from "../UI/Spinner/Spinner";
import classes from "./Survey.module.css";
import { Alert, AlertTitle } from "@material-ui/lab";
import { ClickAwayListener } from "@material-ui/core";
import Modal from "react-bootstrap/Modal";
import { COMPARATIVE, SURVEY_PAGE } from "../../Store/actions/actionsTypes";
import ComparativeTable from "../../Components/ComparativeTable/ComparativeTable";
import * as actionTypes from "../../Store/actions/actionsTypes";
import UseRefWrapper from "../../Containers/SurveyPreview/UseRefWrapper";
import ResizableTextarea from "../../ulitity/ResizableTextarea";
const CONSTANTS = require("../../constants");
class Survey extends Component {
  state = {
    showModal: false,
  };

  handleModalClose = (value) => {
    this.setState({ showModal: false });
    if (value) this.props.handleSubmit();
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.setState({ showModal: true });
  };

  render() {
    const {
      survey,
      isLoading,
      handleSubmit,
      isSuccess,
      isError,
      AuthSubmit,
      isRequired,
    } = this.props;
    const {
      type = "",
      title,
      subTitle,
      ratingIndex,
      submitting,
      ratingIndexAsFootnote,
    } = survey;
    let surveyForm = <Spinner />;
    if (!isLoading) {
      surveyForm = (
        <form onSubmit={this.handleFormSubmit}>
          <header>
            <p className={classes.Rules}>* Required Fields</p>
            <h2 className={classes.Title}>{title}</h2>
            <p className={classes.Subtitle}>{subTitle}</p>
            {ratingIndex && !ratingIndexAsFootnote && (
              <>
                <h5 style={{ textAlign: "left" }}>Rating Index - </h5>

                <ResizableTextarea
                  conditionalStyle={{
                    margin: 0,
                    textAlign: "left",
                    fontSize: "0.9em",
                  }}
                  title={ratingIndex}
                />
              </>
            )}
            <hr style={{ borderTop: "1px solid #bfbfbf" }}></hr>
          </header>

          {/* Construction of comp survey starts */}
          <UseRefWrapper
            caller={SURVEY_PAGE}
            {...survey}
            result={this.props.result}
          />
          {/* Construction ends */}
          {ratingIndexAsFootnote ? (
            <>
              <h5 style={{ textAlign: "left" }}>Rating Index - </h5>
              <ResizableTextarea
                conditionalStyle={{
                  fontSize: "1rem",
                  fontWeight: 400,
                  lineHeight: 1.5,
                  textAlign: "left",
                  color: "#333333",
                }}
                title={ratingIndex}
              />
            </>
          ) : (
            ""
          )}
          <div>
            {isRequired ? (
              <Alert
                severity="error"
                variant="filled"
                style={{ fontSize: "16px", margin: "30px" }}
              >
                All the questions marked with * are mandatory
              </Alert>
            ) : null}
            <Button
              disabled={!AuthSubmit}
              style={{
                display: !AuthSubmit ? "none" : null,
                padding: "15px",
                color: "white",
                backgroundColor: "#34baf2",
                borderRadius: "7px",
              }}
              type="submit"
            >
              Submit Response
            </Button>
            <Button
              style={{
                padding: "15px",
                color: "white",
                backgroundColor: "#ee3a2b",
                borderRadius: "1000px",
                display: !AuthSubmit ? "none" : null,
                marginLeft: "30px",
                position: "fixed",
                bottom: "40px",
                right: "20px",
              }}
              onClick={() => {
                this.props.saveSurvey(
                  this.props.result.result,
                  this.props.values,
                  this.props.type
                );
              }}
            >
              Save As Draft
            </Button>
          </div>
        </form>
      );
    }
    if (!submitting) {
      surveyForm = (
        <div className={classes.Block}>
          <Alert
            severity="error"
            style={{ fontSize: "16px", display: isLoading ? "none" : null }}
            variant="filled"
            className={classes.Title}
          >
            This survey is not accepting submissions
          </Alert>
        </div>
      );
    }
    if (isSuccess) {
      surveyForm = (
        <Alert
          severity="success"
          variant="filled"
          style={{ fontSize: "18px", margin: "30px" }}
        >
          Thank you for taking this survey, your response has been recorded
          successfully.
        </Alert>
      );
    }
    if (isError) {
      surveyForm = (
        <div className={classes.Error}>
          <h3> ERR:Not Authorized</h3>
          <h5>You are not authorized to view this page.</h5>
          <button
            className="LoginToContinue"
            style={{ border: "none", marginTop: "20px" }}
            onClick={redirect}
          >
            Login To Continue
          </button>
        </div>
      );
    }

    return (
      <div
        className={`${classes.Survey} ${
          type === COMPARATIVE && classes.CompSurvey
        }`}
      >
        <Modal
          show={this.state.showModal}
          onHide={() => this.handleModalClose(false)}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <h6>
                You are about to submit the Evaluation Survey. Do you want to
                proceed?
              </h6>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={() => this.handleModalClose(true)}>OK</button>
            <button onClick={() => this.handleModalClose(false)}>Close</button>
          </Modal.Footer>
        </Modal>
        {surveyForm}
      </div>
    );
  }
}
function redirect() {
  localStorage.setItem("redirectLink", window.location.href);
  window.location.href =
    CONSTANTS.URL_CONFIG.BASE_URL + "login?redirectto=" + window.location.href;
}

export default withFormik({
  mapPropsToValues: () => {},
  handleSubmit: (values, { props }) => {
    props.onSubmit(
      { ...props.result.result, ...values },
      props.surveyId,
      props.question_required,
      props.survey?.type || "",
      props.survey?.surveyee_list || []
    );
  },
})(Survey);
