import firebase from "firebase";
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import classes from "./InviteeItem.module.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Alert from "@material-ui/lab/Alert";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@material-ui/core/Tooltip";
import MailAuditData from "../MailAudit/MailAuditData";
import Modal from "react-bootstrap/Modal";
import CONSTANTS from "../../constants";

class InviteeItem extends Component {
  // const [show, setShow] = useState(false);
  state = {
    show: false,
    mailAudit: {},
    pdfReportOptions: [],
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = (audit) => {
    this.setState({ show: true });
    this.setState({ mailAudit: audit }, () => {
      if (
        this.state.mailAudit.type === "REPORT" &&
        this.state.mailAudit.reportDetails
      ) {
        if (
          this.state.mailAudit.reportDetails.reportFormat === "both" ||
          this.state.mailAudit.reportDetails.reportFormat === "pdf"
        ) {
          let getPdfReportOptions =
            this.state.mailAudit.reportDetails.pdfReportOptions;
          let pdfReportOptions = [];
          if (getPdfReportOptions.includeOpenEndedQuestion) {
            pdfReportOptions.push("Open Ended Question");
          }
          if (getPdfReportOptions.includeTable) {
            pdfReportOptions.push("Table");
          }
          if (getPdfReportOptions.includePieChart) {
            pdfReportOptions.push("Pie Chart");
          }
          this.setState({ pdfReportOptions });
        }
      }
    });
  };

  // Function to download the file
  downloadFile = (link) => {
    window.open(link);
  };

  downloadReport = (reportName) => {
    firebase
      .storage()
      .refFromURL(
        CONSTANTS.URL_CONFIG.STORAGE_BUCKET_URL_FOR_GENERATED_REPORT +
          reportName
      )
      .getDownloadURL()
      .then((fileDownloadURL) => {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          link.setAttribute("href", fileDownloadURL);
          link.setAttribute("target", "_blank");
          link.setAttribute("download", reportName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
  };

  render() {
    return (
      <div>
        <div className={classes.item} style={{ marginRight: "30px" }}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead
                style={{ border: "1px solid #ddd" }}
                className={classes.head}
              >
                <TableRow>
                  {/* <TableCell align="center" style={{ color: "white", fontSize: "17px" }}>Name</TableCell> */}
                  <TableCell
                    align="center"
                    style={{ color: "white", fontSize: "17px" }}
                  >
                    {this.props.IsReportAudit
                      ? "Report Sent To"
                      : "Email Invite Sent To"}{" "}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.MailAuditsData.map((audit, i) => (
                  <TableRow
                    key={audit.targetId + "_" + i}
                    className={classes.row}
                    onClick={() => this.handleShow(audit)}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      className={classes.td}
                    >
                      <MailAuditData AuditValue={audit} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Mail Content</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p className={classes.MailHeading}>Sent Time : </p>
              <p className={classes.MailBody}>
                {this.state.mailAudit.time
                  ? moment(this.state.mailAudit.time).format(
                      "DD/MM/YYYY [at] h:mm A"
                    )
                  : ""}
              </p>
            </div>
            <br />
            <div>
              <p className={classes.MailHeading}>Subject : </p>
              <p className={classes.MailBody}>{this.state.mailAudit.subject}</p>
            </div>
            <br />
            <div>
              <p className={classes.MailHeading}>Sender : </p>
              <p className={classes.MailBody}>
                {this.state.mailAudit.mailSender}
              </p>
            </div>
            <br />
            <div>
              <p className={classes.MailHeading}>To : </p>
              <p className={classes.MailBody}>
                {this.state.mailAudit.recipients &&
                this.state.mailAudit.recipients.length > 0
                  ? this.state.mailAudit.recipients.map((recipient, i) => (
                      <p>{recipient}</p>
                    ))
                  : ""}
              </p>
            </div>
            <br />
            <div>
              <p className={classes.MailHeading}>Message : </p>
              <p className={classes.MailBody}>{this.state.mailAudit.body}</p>
            </div>
            <br />
            <div>
              {this.state.mailAudit.attachments &&
              this.state.mailAudit.attachments.length > 0 ? (
                <div>
                  <p className={classes.MailHeading}>Attachments : </p>
                  {this.state.mailAudit.attachments.map((file, i) => (
                    <div>
                      <FontAwesomeIcon
                        icon={faPaperclip}
                        style={{ color: "#BFC3C5" }}
                      ></FontAwesomeIcon>
                      <label
                        className={classes.FileItem + " " + classes.MailBody}
                        onClick={() => this.downloadFile(file.link)}
                      >
                        {" "}
                        {file.name}{" "}
                      </label>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
            {this.state.mailAudit.type === "REPORT" &&
            this.state.mailAudit.reportDetails ? (
              <div>
                <div>
                  <p className={classes.MailHeading}>Report Sent : </p>
                  <p
                    className={classes.MailBody}
                    style={{
                      display:
                        this.state.mailAudit.reportDetails.reportFormat ===
                          "both" ||
                        this.state.mailAudit.reportDetails.reportFormat ===
                          "pdf"
                          ? "block"
                          : "none",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPaperclip}
                      style={{ color: "#BFC3C5" }}
                    ></FontAwesomeIcon>
                    <label
                      className={classes.FileItem + " " + classes.MailBody}
                      onClick={() =>
                        this.downloadReport(
                          this.state.mailAudit.reportDetails.reportName + ".pdf"
                        )
                      }
                    >
                      {" "}
                      {this.state.mailAudit.reportDetails.reportName}.pdf{" "}
                    </label>
                  </p>
                  <p
                    className={classes.MailBody}
                    style={{
                      display:
                        this.state.mailAudit.reportDetails.reportFormat ===
                          "both" ||
                        this.state.mailAudit.reportDetails.reportFormat ===
                          "excel"
                          ? "block"
                          : "none",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPaperclip}
                      style={{ color: "#BFC3C5" }}
                    ></FontAwesomeIcon>
                    <label
                      className={classes.FileItem + " " + classes.MailBody}
                      onClick={() =>
                        this.downloadReport(
                          this.state.mailAudit.reportDetails.reportName +
                            ".xlsx"
                        )
                      }
                    >
                      {" "}
                      {this.state.mailAudit.reportDetails.reportName}.xlsx{" "}
                    </label>
                  </p>
                </div>
                <br />
                <div>
                  <p className={classes.MailHeading}>Report Format : </p>
                  <p className={classes.MailBody}>
                    {this.state.mailAudit.reportDetails.reportFormat === "both"
                      ? "Both - PDF & Excel"
                      : "Only " +
                        this.state.mailAudit.reportDetails.reportFormat}
                  </p>
                </div>
                <br />

                {this.state.mailAudit.reportDetails.reportFormat === "both" ||
                this.state.mailAudit.reportDetails.reportFormat === "pdf" ? (
                  <div>
                    <p className={classes.MailHeading}>
                      PDF Report Includes :{" "}
                    </p>
                    <p className={classes.MailBody}>
                      {this.state.pdfReportOptions
                        .toString()
                        .replaceAll(",", ", ")}
                    </p>
                  </div>
                ) : null}
              </div>
            ) : null}
          </Modal.Body>
          {/* <Modal.Footer>
                        <button className={classes.SendButton} onClick={this.handleClose}>
                            Close
                        </button>
                    </Modal.Footer> */}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(InviteeItem);
