import React, { useRef } from "react";
import ComparativeTable from "../../Components/ComparativeTable/ComparativeTable";
import QuestionList from "../../Components/SurveyPreview/QuestionList";
import * as actionTypes from "../../Store/actions/actionsTypes";
import classes from "./SurveyPreviewContainer.module.css";
import QuestionWrapper from "../../Components/Survey/QuestionWrapper";
import SurveyPageClasses from "../../Components/Survey/Survey.module.css";

const UseRefWrapper = (props) => {
  const ungroupedRef = useRef({});
  const groupedRef = useRef({});
  let questionNumber = 0;
  return props.caller === actionTypes.SURVEY_EDITOR_PAGE ? (
    <div className={classes.ComparativeTableContainer}>
      <QuestionList
        ungroupedRef={ungroupedRef}
        groupedRef={groupedRef}
        {...props}
      />
      {props.survey?.type === actionTypes.COMPARATIVE &&
        props.survey?.surveyee_count > 0 && (
          <ComparativeTable
            ungroupedRef={ungroupedRef}
            groupedRef={groupedRef}
            caller={actionTypes.SURVEY_EDITOR_PAGE}
            {...props}
          />
        )}
    </div>
  ) : props.caller === actionTypes.SURVEY_PAGE ? (
    <div className={SurveyPageClasses.ComparativeTableContainer}>
      <div
        className={
          props.surveyee_count > 0
            ? SurveyPageClasses.CompQuestionListContainer
            : props.surveyee_count == 0
            ? SurveyPageClasses.QuestionListContainer
            : ""
        }
      >
        {props.grouped_questions?.length > 0 && (
          <ul
            className={`${SurveyPageClasses.QuestionList} ${SurveyPageClasses.GroupedQuestionList}`}
            ref={groupedRef}
          >
            {props.grouped_questions?.map((obj, ind) => {
              return (
                <li className={SurveyPageClasses.CompLI} key={ind}>
                  <div
                    style={{
                      backgroundColor: "#34baf2",
                      width: "100%",
                      borderRadius: "1px",
                      padding: "7px",
                    }}
                  >
                    <h6 style={{ color: "white" }}>{obj.groupName}</h6>
                  </div>
                  <ul
                    className={`${SurveyPageClasses.QuestionList} ${SurveyPageClasses.GroupedQuestionList}`}
                  >
                    {obj.questions?.map((id, index) => {
                      let question = props.allQuestionsObject[id];
                      return (
                        <li
                          className={
                            index + 1 == obj.questions.length &&
                            SurveyPageClasses.GroupLastQuestion
                          }
                          key={question._id}
                        >
                          <div>
                            <QuestionWrapper
                              question={question}
                              id={questionNumber++}
                              result={props.result}
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                width: "fit-content",
                              }}
                              surveyee_count={props.surveyee_count}
                            />
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            })}
          </ul>
        )}

        {props.ungrouped_questions?.length > 0 && (
          <ul
            ref={ungroupedRef}
            className={SurveyPageClasses.SurveyPageUngroupedQuestionList}
          >
            {props.ungrouped_questions?.map((quest) => {
              let question = props.allQuestionsObject[quest];
              return (
                <li
                  className={SurveyPageClasses.SurveyPageUngroupedQuestion}
                  key={question._id}
                >
                  <div>
                    <QuestionWrapper
                      question={question}
                      id={questionNumber++}
                      result={props.result}
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        width: "fit-content",
                      }}
                      surveyee_count={props.surveyee_count}
                    />
                  </div>
                  <hr
                    style={{
                      borderTop: "1px dashed #d9d9d9",
                      width: "90%",
                      marginBottom: "0px",
                    }}
                  />
                </li>
              );
            })}
          </ul>
        )}
        {/* <ul>
          {props.type !== actionTypes.COMPARATIVE &&
            props.questions?.map((question) => {
              return (
                <li key={question._id}>
                  <div>
                    <QuestionWrapper
                      question={question}
                      id={questionNumber++}
                      result={props.result}
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        width: "fit-content",
                      }}
                    />
                  </div>
                  <hr
                    style={{
                      borderTop: "1px dashed #d9d9d9",
                      width: "90%",
                    }}
                  />
                </li>
              );
            })}
        </ul> */}
      </div>
      {props.type === actionTypes.COMPARATIVE && props.surveyee_count > 0 && (
        <ComparativeTable
          {...props}
          ungroupedRef={ungroupedRef}
          groupedRef={groupedRef}
          caller={actionTypes.SURVEY_PAGE}
        />
      )}
    </div>
  ) : (
    ""
  );
};
export default UseRefWrapper;
