import React, { Fragment, useState } from "react";
import classes from "./SurveyEditerPage.module.css";
// import { AutoAffix } from "react-overlays";
import SurveyPreviewContainer from "../Containers/SurveyPreview/SurveyPreviewContainer";
import EditFooter from "../Containers/EditFooter/EditFooter";
import ModalEditer from "../Components/UI/ModalEditer/ModalEditer";
import ScrollTop from "@nzambello/react-scrolltop";
import QuestionListMoblile from "../Containers/QuestionListPanel/QuestionListMoblie";
import firebase from "firebase";
import SurveyEditDrawer from "../Containers/SurveyEditDrawer/SurveyEditDrawer";

const SurveyEditerPage = () => {
  firebase.analytics().logEvent("Survey Edit page is rendered");
  const [open, setOpen] = useState(false);

  window.onbeforeunload = function () {
    return true;
  };

  return (
    <Fragment>
      {/* <h3 className={classes.SmallScreen}>Please use bigger screen device for Editer !</h3> */}
      <div className={classes.SurveyEditerPage}>
        <div className={classes.SurveyPreview}>
          <SurveyPreviewContainer setOpen={setOpen} />
        </div>
        {/* <AutoAffix> */}
        {/* <> */}
        <SurveyEditDrawer open={open} setOpen={setOpen}></SurveyEditDrawer>
        {/* </> */}
        {/* </AutoAffix> */}
        <div className={classes.Footer}>
          <EditFooter />
          <ScrollTop />
        </div>
        <div className={classes.ForMobile}>
          <ModalEditer />
          <div className={classes.Container}>
            <QuestionListMoblile />
            <EditFooter />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SurveyEditerPage;
