import * as actionTypes from "./actionsTypes";
import { QuestionTypes } from "../../ulitity/constants/Questions";
import every from "lodash/every";

export const canReportTypes = [
  QuestionTypes.CHECKBOXES,
  QuestionTypes.DROPDOWN,
  QuestionTypes.MULTI_CHOICE,
  QuestionTypes.RATING,
];
export const openEndedTypes = [
  QuestionTypes.SINGLE_LINE_TEXT,
  QuestionTypes.MULTI_LINE_TEXT,
];

export const updateFilter = (filter, newValue) => {
  let newFilter = { ...filter };
  if (!newFilter[newValue.question]) {
    newFilter[newValue.question] = {};
  }

  if (!newValue.subOption) {
    newFilter[newValue.question][newValue.option] = newValue.value;
  } else {
    if (!newFilter[newValue.question][newValue.option]) {
      newFilter[newValue.question][newValue.option] = {
        [newValue.subOption]: newValue.value,
      };
    }
  }

  return {
    type: actionTypes.SET_FILTER,
    payload: newFilter,
  };
};

export const hasFilterMap = (state) => {
  let reportFilter = state.reportFilter;
  let hasFilterMap = {};
  Object.keys(reportFilter).forEach((questionId) => {
    // let answer = reportFilter[questionId];
    // let hasFilter = Object.keys(answer).some(answerId => {
    //   if (typeof answer[answerId] === 'boolean') {
    //     return answer[answerId];
    //   } else if (typeof answer[answerId] === 'object') {
    //     return Object.keys(answer[answerId]).some(k => answer[answerId][k]);
    //   }
    // });
    let hasFilter = null;
    hasFilterMap[questionId] = hasFilter;
  });
  return hasFilterMap;
};

export const resultsToReport = (state) => {
  let { survey, results, reportFilter } = state;

  if (!survey || !survey.questions) {
    return {
      reportResult: [],
      results: [],
    };
  }
  let filterMap = hasFilterMap(state);
  let filtedResults = results.filter((result) => {
    if (!Array.isArray(result.result)) return true;

    return every(Object.keys(result.result), (questionId) => {
      if (!filterMap?.[questionId]) return true;

      let answer = result.result[questionId];
      if (typeof answer === "string") {
        return reportFilter[questionId][answer];
      } else if (typeof answer === "object") {
        return Object.keys(answer).some(
          (subKey) => reportFilter[questionId][subKey]
        );
      } else {
        return true;
      }
    });
  });

  let reportResult = survey.questions
    .filter((q) => canReportTypes.indexOf(q.type) !== -1)
    .map((question) => {
      let id = question._id;
      let optionMap = {};
      let otherOptionResults = [];
      let averageRating = 0;
      question.options.forEach((o) => {
        if (question.type !== "RATING") {
          optionMap[o._id] = {
            content: o.content,
            count: 0,
          };
        } else {
          let value = question.stars;
          for (let i = 1; i <= value; i++) {
            optionMap[i] = {
              content: i,
              count: 0,
            };
          }
        }
      });
      filtedResults.forEach((result) => {
        let questionAnswer = result.result[id];
        if (question.otherOptionIncluded) {
          otherOptionResults.push({
            remarks: result.result[id + "_remarks"]
              ? result.result[id + "_remarks"]
              : "",
          });
        }
        if (typeof questionAnswer === "string") {
          optionMap[questionAnswer].count++;
        } else if (typeof questionAnswer === "object") {
          Object.keys(questionAnswer).forEach(
            (answer) =>
              questionAnswer[answer] &&
              optionMap[answer] &&
              optionMap[answer].count++
          );
        }
      });
      if (question.type === "RATING") {
        let sumOfProduct = 0,
          totalReview = 0;
        for (let result in optionMap) {
          sumOfProduct += optionMap[result].content * optionMap[result].count;
          totalReview += optionMap[result].count;
        }
        averageRating = (sumOfProduct / totalReview).toFixed(2);
      }
      return {
        _id: id,
        title: question.title,
        stats: Object.keys(optionMap).map((key) => {
          return {
            name: optionMap[key].content,
            value: optionMap[key].count,
          };
        }),
        averageRating: question.type === "RATING" ? averageRating : null,
        optherOptionValue: question.otherOptionIncluded
          ? question.otherOptionValue
          : null,
        remarks: question.otherOptionIncluded ? otherOptionResults : null,
      };
    });
  return {
    reportResult,
    results: filtedResults,
  };
};
