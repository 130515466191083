import React from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

const alphabets = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const HtmlTableToXlsx = (props) => {
  const createDownLoadData = () => {
    handleExport().then((url) => {
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute("download", `${props.filename}.xlsx`);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);

    // The application/octet-stream MIME type is used for unknown binary files.
    // It preserves the file contents, but requires the receiver to determine file type,
    // for example, from the filename extension.
    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    // The ArrayBuffer() constructor is used to create ArrayBuffer objects.
    // create an ArrayBuffer with a size in bytes
    const buf = new ArrayBuffer(s.length);

    //create a 8 bit integer array
    const view = new Uint8Array(buf);

    //charCodeAt The charCodeAt() method returns an integer between 0 and 65535 representing the UTF-16 code
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleExport = () => {
    //create a new workbook
    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.table_to_sheet(props.tableRef.current, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, "Test");

    // binary large object
    // Since blobs can store binary data, they can be used to store images or other multimedia files.

    const workbookBlob = workbook2blob(wb);

    return addStyle(workbookBlob);
  };

  const addStyle = (workbookBlob) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.usedRange().style({
          fontFamily: "Arial",
          verticalAlignment: "center",
          horizontalAlignment: "center",
          fontSize: 11,
        });

        const isSubtitlePresent = props.subTitle ? 1 : 0;

        // to set width for columns
        sheet.column("A").width(10);
        const columnsArray = new Array(props.noOfColumns).fill(0);
        columnsArray.forEach((_, index) => {
          if (index > 0) {
            if (index === props.noOfColumns - 1 && props.ratingQuestFound)
              sheet.column(alphabets[index]).width(12);
            else sheet.column(alphabets[index]).width(35);
          }
        });

        sheet
          .range(
            `A1:${alphabets[props.noOfColumns - 1]}${10 + isSubtitlePresent}`
          )
          .style({
            bold: true,
          });

        sheet
          .range(
            `A3:${alphabets[props.noOfColumns - 1]}${8 + isSubtitlePresent}`
          )
          .style({
            horizontalAlignment: "left",
          });

        sheet.range(`A1:${alphabets[props.noOfColumns - 1]}1`).style({
          fontSize: 18,
        });

        sheet.range(`A2:${alphabets[props.noOfColumns - 1]}2`).style({
          fontSize: 15,
        });

        if (props.subTitle) {
          sheet.range(`A3:${alphabets[props.noOfColumns - 1]}3`).style({
            fontSize: 14,
            bold: false,
            horizontalAlignment: "center",
          });
        }

        sheet.column("B").style({
          horizontalAlignment: "left",
          wrapText: true,
        });

        sheet.cell(`B${10 + isSubtitlePresent}`).style({
          horizontalAlignment: "center",
        });

        // to set border
        sheet
          .range(
            `A1:${alphabets[props.noOfColumns - 1]}${sheet._rows.length - 1}`
          )
          .style({
            border: true,
            wrapText: true,
          });

        // to merge question and remarks cells
        props.remarksCells.forEach((cellNo) => {
          const mergeRange = sheet
            .range(
              `A${cellNo + isSubtitlePresent + 10}:A${
                cellNo + isSubtitlePresent + 11
              }`
            )
            .style({
              horizontalAlignment: "center",
              verticalAlignment: "center",
            });
          mergeRange.merged(true);
        });
        props?.headingRows?.length > 0 &&
          props.headingRows.forEach((rowNum) => {
            sheet.cell(`A${rowNum}`).style({
              horizontalAlignment: "left",
              bold: true,
            });
          });
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <button
      className={props.className}
      type="button"
      onClick={createDownLoadData}
    >
      {props.buttonText}
    </button>
  );
};

export default HtmlTableToXlsx;
