import React, { Component } from "react";

import SurveyHeader from "./SurveyHeader";
import { Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import classes from "./SurveyHeader.module.css";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { COMPARATIVE } from "../../Store/actions/actionsTypes";
import GroupingDialogContainer from "../../Containers/GroupQuestions/GroupingDialogContainer";
import ResizableTextarea from "../../ulitity/ResizableTextarea";

const checkBoxStyles = (theme) => ({
  root: {
    color: "#34baf2",
    "&$checked": {
      color: "#34baf2",
    },
    marginRight: "0px",
  },
  checked: {},
});

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);
class SurveyPreview extends Component {
  constructor() {
    super();
    this.state = {
      openGrouping: false,
      // surveyeeCount: "",
    };
  }
  onHeaderActiveClick = (e) => {
    this.props.onHeaderActive();
    this.props.setOpen(true);
  };
  handleGrouping = (e) => {
    e.stopPropagation();
    this.setOpenGrouping(true);
  };
  setOpenGrouping = (state) => {
    this.setState({ openGrouping: state });
  };
  // handleChange = (e) => {
  //   let numString = String(e.target.value);
  //   if (numString !== "." || "-") {
  //     let pattern = /^(1[0-9]|[1-9])$|^20$/;
  //     if (pattern.test(numString)) {
  //       onSurveyeeCountUpdate(Number(e.target.value));

  //       // this.setState({ surveyeeCount: Number(e.target.value) });
  //     }
  //     if (numString == "") {
  //       onSurveyeeCountUpdate(Number(numString));

  //       // this.setState({ surveyeeCount: numString });
  //     }
  //   }
  // };
  render() {
    const {
      survey,
      onOpenModal,
      createComparativeSurvey,
      onSurveyeeCountChange,
      // onSurveyeeCountUpdate,
    } = this.props;

    return (
      <div>
        <form>
          <SurveyHeader
            title={survey.title}
            subTitle={survey.subTitle}
            onActive={this.onHeaderActiveClick}
            onOpenModal={() => onOpenModal()}
            survey={survey}
          />
          <div className={classes.CompSurvey}>
            <label
              className={classes.ReportLayoutOptionText}
              htmlFor="Comp_Survey"
            >
              <CustomCheckbox
                id="Comp_Survey"
                name="Comp_Survey"
                checked={survey?.type === COMPARATIVE}
                onChange={() => createComparativeSurvey(survey?.type)}
                iconStyle={{ fill: "#34baf2" }}
                style={{
                  marginLeft: "10px",
                  marginRight: "0px",
                }}
              />
              Create Comparative Survey
            </label>

            {survey.type === COMPARATIVE && (
              <Button
                onClick={this.handleGrouping}
                variant="outlined"
                color="primary"
                className={classes.GroupButton}
              >
                Group Questions
              </Button>
            )}
            {this.state.openGrouping && (
              <GroupingDialogContainer
                surveyRaw={survey}
                setOpenGrouping={this.setOpenGrouping}
                openGrouping={this.state.openGrouping}
              />
            )}

            {survey.type === COMPARATIVE && (
              <div className={classes.CountEnabled}>
                Surveyee Count:
                <input
                  type="number"
                  value={
                    survey.surveyee_count == 0 ? "" : survey.surveyee_count
                  }
                  placeholder={0}
                  className={classes.CountField}
                  disabled
                />
                <ButtonGroup>
                  <Button
                    aria-label="reduce"
                    onClick={(e) => {
                      e.stopPropagation();
                      survey.surveyee_count > 0 && onSurveyeeCountChange("DEC");
                    }}
                  >
                    <RemoveIcon fontSize="small" />
                  </Button>
                  <Button
                    aria-label="increase"
                    onClick={(e) => {
                      e.stopPropagation();

                      survey.surveyee_count < 20 &&
                        onSurveyeeCountChange("INC");
                    }}
                  >
                    <AddIcon fontSize="small" />
                  </Button>
                </ButtonGroup>
              </div>
            )}
          </div>
          {this.props.children}
        </form>
        {survey.ratingIndexAsFootnote ? (
          <>
            <hr
              style={{
                borderTop: "1px dashed #d9d9d9",
                width: "100%",
                marginBottom: "0px",
              }}
            />
            <h5>Rating Index - </h5>
            <ResizableTextarea
              conditionalStyle={{
                fontSize: "1rem",
                fontWeight: 400,
                lineHeight: 1.5,
                textAlign: "left",
                color: "#333333",
              }}
              title={survey.ratingIndex}
            />
          </>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default SurveyPreview;
