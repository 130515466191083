import React from "react";

import classes from "./ReportTable.module.css";

const remarksReportTable = (props) => {
  return (
    <div className={classes.ReportTable}>
      <table>
        <thead>
          <tr className={classes.tableHeading}>
            <th>Sl No</th>
            <th>{props.columnName}</th>
          </tr>
        </thead>
        <tbody>
          {props.data.map((row, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{row.remarks}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default remarksReportTable;
