import React, { Component } from "react";
import Button from "@material-ui/core/Button";

import classes from "./OpenResultsTable.module.css";
import Icon from "@material-ui/core/Icon";

import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ResizableTextarea from "../../ulitity/ResizableTextarea";
class ResultsTable extends Component {
  toggleSelectAll() {
    let {
      onSelectAll,
      onUnSelectAll,
      grid: { openresults },
    } = this.props;
    if (this.props.allSelect) {
      onUnSelectAll(openresults);
    } else {
      onSelectAll(openresults);
    }
  }

  render() {
    let { onSelectRow, rowSelects, allSelected, onDeleteRow, surveyId } =
      this.props;
    let { opencolumns, openresults } = this.props.grid;
    let anySelected = Object.keys(rowSelects).some((k) => rowSelects[k]);
    let isDisable = true;
    if (anySelected) {
      isDisable = false;
    }

    return (
      <div className={classes.ResultsTable}>
        {/*-----------------------------------------------------------------------------*/}
        {opencolumns.map((col) => {
          return (
            <div>
              <div className={classes.TableWrapper}>
                <ResizableTextarea
                  key={col.columnName}
                  title={"Q." + col.displayName}
                  additionalClass={classes.Title}
                />
                <div className={classes.innertable}>
                  <table className={classes.table}>
                    <thead className={classes.tableheader}>
                      <tr className={classes.tableHeading}>
                        {/* <th className={classes.SelectBox}>
                  <input
                    type="checkbox"
                    onClick={e => e.stopPropagation()}
                    onChange={this.toggleSelectAll.bind(this)}
                    checked={allSelected}
                  />
                </th> */}
                        <th className={classes.serialNo}>Sl No.</th>
                        <th>Answers</th>
                      </tr>
                    </thead>
                    <tbody className={classes.tbodyy}>
                      {openresults.map((result, index) => {
                        return (
                          <tr key={result.id}>
                            {/* <td className={classes.SelectBox}>
                      <input
                        type="checkbox"
                        onClick={e => e.stopPropagation()}
                        onChange={() => onSelectRow(result.id)}
                        checked={rowSelects[result.id]}
                      />
                    </td> */}
                            <td className={classes.serialNo}>{index + 1}</td>
                            <td key={index}>{result[col.columnName]}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default ResultsTable;
