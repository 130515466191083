import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import firebase from "firebase";
import newId from "../../ulitity/idGenerator";
import Notiflix from "notiflix-react";
import * as actionTypes from "../../Store/actions/actionsTypes";
import SurveyItem from "../../Components/SurveyList/SurveyItem";
import CompanyModal from "../../Components/SurveyList/CompanyModal";
import Spinner from "../../Components/UI/Spinner/Spinner";
import ConsolidatedAudit from "../../Components/MailAudit/ConsolidatedAudit";
import { fetchSurveys } from "../../Store/actions/surveys";
import "react-toastify/dist/ReactToastify.css";
import Button from "react-bootstrap/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import classes from "./NewSurvey/NewSurvey.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as async from "async";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import { Next } from "react-bootstrap/esm/PageItem";
import http from "../../lib/http-service";
import "./SurveyList.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { db } from "../../firebase-config";
import Checkbox from "@material-ui/core/Checkbox";

class SurveyList extends Component {
  state = {
    companyListAsAdmin: null,
    selectedCompany: {},
    newStateValue: {},
    showModal: false,
    selectedSurveys: {},
    senddialogopen: false,
    auditDialogOpen: false,
    subject: `${this.props.compname} - Board Evaluation Surveys`,
    to: [],
    message: `Please find the link for the Board Evaluation Survey for the year ${new Date().getFullYear()}. Request you to fill up this survey.For any assistance please contact ${
      firebase.auth().currentUser.email
    } 
    Thank you for your time.
    Yours sincerely,
    ${firebase.auth().currentUser.displayName}
    `,
    attachments: [],
    consolidated_id: "",
    emails: [],
    activeEmailGroupId: "",
  };

  getEmailGroups = () => {
    db.collection(`company/${this.props.compId}/emailGroups`).onSnapshot(
      (snapshot) => {
        const results = [];
        if (snapshot.size > 0) {
          snapshot.forEach((doc) =>
            results.push({ ...doc.data(), id: doc.id })
          );
          this.setState({ allEmailGroups: results });
          this.props.setEmailGroups(results);
        } else {
          this.setState({ allEmailGroups: [] });
          this.props.setEmailGroups([]);
        }
      }
    );
  };

  componentDidMount() {
    this.props.onFetchSurveys(this.props.token, this.props.userId);
    firebase.analytics().logEvent("Survey List page is rendered");
    this.getEmailGroups();
  }

  handleSubmit = (checkboxList) => {
    this.setState({ ...this.state, selectedCompany: checkboxList });
    this.upddateClone(this.state.selectedCompany);
  };

  handleModalOpen = () => {
    this.setState({ showModal: true });
  };

  handleModalClose = () => {
    this.setState({ showModal: false });
  };
  extractSelectedSurveys = () => {
    let totalSelectedSurveys = [];
    let selectedSurveys = { ...this.state.selectedSurveys };
    Object.keys(selectedSurveys).forEach((finYearIndex) => {
      selectedSurveys[finYearIndex].forEach((id) => {
        totalSelectedSurveys.push(id);
      });
    });
    return totalSelectedSurveys;
  };
  sendEmail = () => {
    const { subject, message, attachments, consolidated_id } = this.state;
    let totalSelectedSurveyIds = this.extractSelectedSurveys();
    let con_id = newId();
    const emarr = this.state.emails;
    const mailAudit = {
      _id: newId(),
      recipients: emarr,
      subject: subject,
      body: message,
      time: new Date().getTime(),
      type: "CONSOLIDATED",
      targetId: con_id,
      attachments: attachments,
      mailSender: firebase.auth().currentUser.email,
      surveyids: totalSelectedSurveyIds,
    };

    db.collection(
      `consolidatedMailAudit/${this.props.compId}/consolidatedMailAuditList`
    )
      .doc(String(con_id))
      .set(mailAudit)
      .then(() => {
        const fetcharr = [];
        for (var em in emarr) {
          Notiflix.Loading.Init({ svgColor: "#3bbfde" });
          Notiflix.Loading.Pulse("Sending Mail");
          fetcharr.push(
            http.get(
              `/sendConsolidatedMailInvites?dest=${emarr[em]}&subject=${mailAudit.subject}&surveyids=${totalSelectedSurveyIds}&compid=${this.props.compId}&mailAuditId=${mailAudit._id}&consolidatedId=${con_id}`
            )
          );
        }
        let split_surveys = totalSelectedSurveyIds;
        for (var i = 0; i < split_surveys.length; i++) {
          let temp_audit = mailAudit;
          temp_audit.targetId = split_surveys[i];
          temp_audit.type = "SURVEY";
          db.collection(`mailAudit/${split_surveys[i]}/mailAuditList`)
            .doc(String(mailAudit._id))
            .set(mailAudit);
        }
        Promise.all(fetcharr)
          .then((res) => {
            if (res[0].status === 200) {
              db.collection(`logs/${this.props.compId}/logsList`)
                .add({
                  name: firebase.auth().currentUser.displayName,
                  email: firebase.auth().currentUser.email,
                  timestamp: new Date().getTime(),
                  entity: "Consolidated Survey Invites",
                  operation: "SENT",
                  payload: {
                    email: this.state.emails.join(", "),
                    number: totalSelectedSurveyIds.length,
                  },
                })
                .then(() => {
                  this.setState({
                    ...this.state,
                    subject: `${this.props.compname} - Board Survey Invite`,
                  });
                  Notiflix.Loading.Remove();
                  Notiflix.Report.Init({
                    width: "400px",
                    titleFontSize: "20px",
                    messageFontSize: "17px",
                    success: { buttonBackground: "#34baf2" },
                  });
                  Notiflix.Report.Success(
                    "Email Invite Successful",
                    "Email Invitation Has Been Successfully Sent To " +
                      this.state.emails.join(", "),
                    "ok",
                    () => {
                      this.handleSendDialogClose();
                      this.handleEmailGroup("");
                    }
                  );
                  this.setState({
                    ...this.state,
                    selectedSurveys: [],
                    attachments: [],
                  });
                })
                .catch(() => {
                  Notiflix.Report.Init({
                    width: "400px",
                    titleFontSize: "20px",
                    messageFontSize: "17px",
                    success: { buttonBackground: "#34baf2" },
                  });
                  Notiflix.Report.Failure(
                    "Email Invite Successful but email could not be saved",
                    "Invite was successfully sent but the email could not be saved, this is an unexpecrted error",
                    "ok"
                  );
                  this.setState({ ...this.state, emails: [] });
                  this.setState({ ...this.state, attachments: [] });
                });
            }
          })
          .catch((err) => {
            Notiflix.Loading.Remove();
            Notiflix.Report.Init({
              width: "400px",
              titleFontSize: "20px",
              messageFontSize: "17px",
              success: { buttonBackground: "#EE3A2C" },
            });
            Notiflix.Report.Failure(
              "Email Invite Unsucessful",
              "Email Invitation Could Not Be Sent!",
              "ok"
            );
            this.setState({ emails: [] });
            this.setState({ attachments: [] });
          });
      });
  };

  upddateClone = (selectedCompany) => {
    var comp = this;
    if (this.state.selectedCompany.size == 0) {
      Notiflix.Report.Init({
        width: "400px",
        titleFontSize: "20px",
        messageFontSize: "17px",
        success: { buttonBackground: "#EE3A2C" },
      });
      Notiflix.Report.Warning(
        "Company not selected",
        "Please select company to clone the survey",
        "ok"
      );
      comp.handleModalClose();
    } else {
      Notiflix.Loading.Init({ svgColor: "#3bbfde" });
      Notiflix.Loading.Pulse("Cloning Survey");
      selectedCompany.forEach((company_id) => {
        let copy_newStateValue = this.state.newStateValue;
        copy_newStateValue._id = newId();
        db.collection(`surveys/${company_id}/surveysList`)
          .doc(String(copy_newStateValue._id))
          .set(copy_newStateValue)
          .then(() => {
            db.collection(`logs/${company_id}/logsList`)
              .add({
                name: firebase.auth().currentUser.displayName,
                email: firebase.auth().currentUser.email,
                timestamp: new Date().getTime(),
                entity: "Surveys",
                operation: "CREATED",
                payload: copy_newStateValue,
              })
              .then(() => {
                Notiflix.Loading.Remove();
                //component.props.onGetSurveyId(copy_newStateValue._id);
                Notiflix.Report.Init({
                  width: "400px",
                  titleFontSize: "20px",
                  messageFontSize: "17px",
                  success: { buttonBackground: "#EE3A2C" },
                });
                Notiflix.Report.Success(
                  "Survey Cloned",
                  "The survey was cloned to selected company successfully",
                  "ok"
                );
                comp.handleModalClose();
              })
              .catch((err) => {
                alert(err);
              });
          })
          .catch((err) => {
            alert(err);
          });
      });
    }
  };

  deleteSurveyHandler = (surveyId) => {
    const comp = this;
    Notiflix.Loading.Init({ svgColor: "#3bbfde" });
    Notiflix.Loading.Pulse("Deleting Survey");

    db.collection(`surveys/${this.props.compId}/surveysList`)
      .doc(String(surveyId))
      .get()
      .then((doc) => {
        if (!doc.exists) return;

        db.collection(`logs/${comp.props.compId}/logsList`).add({
          name: firebase.auth().currentUser.displayName,
          email: firebase.auth().currentUser.email,
          timestamp: new Date().getTime(),
          entity: "Surveys",
          operation: "DELETED",
          payload: { ...doc.data() },
        });
      })
      .then(() => {
        db.collection(`surveys/${this.props.compId}/surveysList`)
          .doc(String(surveyId))
          .delete()
          .then(() => {
            Notiflix.Loading.Remove();
            Notiflix.Report.Init({
              width: "400px",
              titleFontSize: "20px",
              messageFontSize: "17px",
              success: { buttonBackground: "#EE3A2C" },
            });
            Notiflix.Report.Success(
              "Survey Deleted",
              "The selected survey was deleted successfully",
              "ok"
            );
            this.props.onFetchSurveys(this.props.token, this.props.userId);
          });
      });
  };

  onSelectSurvey = (id, SELECT_ALL = "", i = "", survey) => {
    let selectedSurveys = { ...this.state.selectedSurveys };
    if (!SELECT_ALL) {
      if (i in selectedSurveys) {
        if (selectedSurveys[i].indexOf(id) === -1) {
          let ids = [...selectedSurveys[i], id];
          selectedSurveys[i] = [...ids];
          this.setState({
            ...this.state,
            selectedSurveys: { ...selectedSurveys },
          });
        } else {
          let newSelectedSurveys = selectedSurveys[i].filter(
            (surveyId) => surveyId !== id
          );
          selectedSurveys[i] = [...newSelectedSurveys];
          this.setState({
            ...this.state,
            selectedSurveys: { ...selectedSurveys },
          });
        }
      } else {
        selectedSurveys[i] = [];
        selectedSurveys[i].push(id);
        this.setState({
          ...this.state,
          selectedSurveys: { ...selectedSurveys },
        });
      }
    } else if (SELECT_ALL === actionTypes.SELECT_ALL) {
      if (
        i in selectedSurveys &&
        selectedSurveys[i].length === survey.data.length
      ) {
        selectedSurveys[i] = [];
        this.setState({
          ...this.state,
          selectedSurveys: { ...selectedSurveys },
        });
      } else {
        let ids = [];
        survey.data.forEach((data) => {
          ids.push(data._id);
        });
        selectedSurveys[i] = [...ids];
        this.setState({
          ...this.state,
          selectedSurveys: { ...selectedSurveys },
        });
      }
    }
  };

  handleEmailGroup = (emailGroupId) => {
    const filterEmailGroup = this.props.emailGroups.filter(
      (emailGroup) => emailGroup.id === emailGroupId
    );
    this.setState({
      ...this.state,
      activeEmailGroupId: emailGroupId,
      emails: filterEmailGroup[0]?.emails || [],
    });
  };

  handleSendDialogClose = () => {
    this.setState({ ...this.state, senddialogopen: false });
  };

  handleAuditDialogClose = () => {
    this.setState({ ...this.state, auditDialogOpen: false });
  };

  handleSendDialogOpen = () => {
    if (this.state.consolidated_id === "") {
      this.setState({ ...this.state, consolidated_id: newId() });
      this.setState({ ...this.state, senddialogopen: true });
    } else {
      this.setState({ ...this.state, senddialogopen: true });
    }
  };

  handleAuditDialogOpen = () => {
    this.setState({ ...this.state, auditDialogOpen: true });
  };

  cloneSurveyHandler = (surveyId, compId) => {
    Notiflix.Loading.Init({ svgColor: "#3bbfde" });
    Notiflix.Loading.Pulse("Cloning Survey");
    var component = this;
    let newStateValue = {};
    db.collection(`surveys/${compId}/surveysList`)
      .doc(String(surveyId))
      .get()
      .then((doc) => {
        const surveysData = { ...doc.data(), id: doc.id };
        const { content = {} } = surveysData;
        newStateValue = (content.type === actionTypes.COMPARATIVE && {
          _id: newId(),
          userId: component.props.userId,
          content: {
            allQuestionsObject: content.allQuestionsObject || {},
            surveyee_count: content.surveyee_count || 0,
            surveyee_list: content.surveyee_list || [],
            type: content.type,
            ungrouped_questions: content.ungrouped_questions || [],
            grouped_questions: content.grouped_questions || [],
            title: `${content.title}- cloned`,
            subTitle: content.subTitle,
            creatorDate: Date(),
            lastModified: Date(),
            openTill: new Date().toISOString().split("T")[0] + "T23:59",
            questions: content.questions,
            submitting: true,
            ratingIndex: content.ratingIndex || "",
            ratingIndexAsFootnote: content.ratingIndexAsFootnote || false,
          },
        }) || {
          _id: newId(),
          userId: component.props.userId,
          content: {
            title: `${content.title}- cloned`,
            subTitle: content.subTitle,
            creatorDate: Date(),
            lastModified: Date(),
            openTill: new Date().toISOString().split("T")[0] + "T23:59",
            questions: content.questions,
            submitting: true,
            ungrouped_questions: content.ungrouped_questions || [
              ...content.questions,
            ],
            grouped_questions: content.grouped_questions || [],
            allQuestionsObject: content.allQuestionsObject || {},
          },
        };
        component.setState({ newStateValue });
        db.collectionGroup("roles")
          .where("email", "==", firebase.auth().currentUser.email)
          .where("role", "in", ["Admin", "Editor"])
          .get()
          .then((docs) => {
            const docData = [],
              companyIDs = [];
            docs.forEach((doc) => docData.push({ ...doc.data(), id: doc.id }));
            docData.forEach((doc) => companyIDs.push(String(doc.companyID)));
            db.collection("company")
              .where(
                firebase.firestore.FieldPath.documentId(),
                "in",
                companyIDs
              )
              .get()
              .then((childDocs) => {
                const companyData = [];
                childDocs.forEach((doc) =>
                  companyData.push({ ...doc.data(), id: doc.id })
                );
                Notiflix.Loading.Remove();
                component.setState({
                  companyListAsAdmin: [...companyData],
                  showModal: true,
                });
              })
              .catch((err) => console.log(`Failed to fetch companies `, err));
          })
          .catch((err) => console.log(`Failed to fetch roles `, err));
      })
      .catch((error) => {
        alert(error);
      });
  };

  // Function to upload files to storage
  uploadFile = (files) => {
    Notiflix.Loading.Init({ svgColor: "#3bbfde" });
    Notiflix.Loading.Pulse("Uploading Files");

    const { attachments } = this.state;
    const storage = firebase.storage().ref();
    const filePath =
      "uploads/" +
      this.props.compId +
      "/" +
      this.state.consolidated_id +
      "/" +
      new Date().getTime() +
      "/";

    let totalUploadedSize = 0;
    if (attachments.length > 0) {
      attachments.forEach((fileData) => {
        totalUploadedSize += fileData.size;
      });
    }

    if (files.length > 0) {
      for (var i = 0; i < files.length; i++) {
        totalUploadedSize += files[i]["size"];
      }
    }

    let totalSize = Math.ceil(totalUploadedSize / 3) * 4;

    if (totalSize > 9000000) {
      Notiflix.Loading.Remove();
      Notiflix.Report.Init({
        width: "400px",
        titleFontSize: "20px",
        messageFontSize: "17px",
        success: { buttonBackground: "#EE3A2C" },
      });
      Notiflix.Report.Failure(
        "You have exceeded maximum limit",
        "More than 10MB "
      );
      document.getElementById("mailFiles").value = null;
      return false;
    }

    async.eachSeries(
      files,
      (singleFile, uploadCb) => {
        const storageRef = storage.child(filePath + singleFile.name);
        storageRef.put(singleFile).then((snapshot) => {
          const ref = storage.child(snapshot.metadata.fullPath);
          ref
            .getDownloadURL()
            .then((url) => {
              const fileUploaded = {
                name: snapshot.metadata.name,
                size: snapshot.metadata.size,
                link: url,
                type: snapshot.metadata.contentType,
                filePath: filePath + singleFile.name,
              };
              attachments.push(fileUploaded);
              uploadCb();
            })
            .catch((error) => {
              uploadCb(error);
            });
        });
      },
      (asyncLoopError) => {
        if (asyncLoopError) {
          console.log(
            "Error while batch upload files. Error => ",
            asyncLoopError
          );
        } else {
          Notiflix.Loading.Remove();
          this.setState({ attachments: attachments });
          document.getElementById("mailFiles").value = null;
        }
      }
    );
  };

  removeFile = (index) => {
    Notiflix.Loading.Init({ svgColor: "#3bbfde" });
    Notiflix.Loading.Pulse("Deleting File");
    const { attachments } = this.state;
    const storage = firebase.storage().ref();
    var desertRef = storage.child(attachments[index].filePath);
    desertRef
      .delete()
      .then((success) => {
        attachments.splice(index, 1);
        this.setState({ attachments: attachments });
        Notiflix.Loading.Remove();
      })
      .catch((error) => {
        Notiflix.Loading.Remove();
        Notiflix.Report.Init({
          width: "400px",
          titleFontSize: "20px",
          messageFontSize: "17px",
          success: { buttonBackground: "#EE3A2C" },
        });
        Notiflix.Report.Failure("File deletion failed");
      });
  };

  // Function to download the file
  downloadFile = (link) => {
    window.open(link);
  };

  pushTemplateHandler = (surveyId, compId) => {
    Notiflix.Loading.Init({ svgColor: "#3bbfde" });
    Notiflix.Loading.Pulse("Pushing As A Template");
    var component = this;
    db.collection(`surveys/${compId}/surveysList`)
      .doc(String(surveyId))
      .get()
      .then((doc) => {
        const surveysData = { ...doc.data(), id: doc.id };
        const { content = {} } = surveysData;
        let newStateValue = {
          _id: surveyId,
          userId: component.props.userId,
          content: {
            title: content.title,
            subTitle: content.subTitle,
            creatorDate: Date(),
            lastModified: Date(),
            openTill: new Date().toISOString().split("T")[0] + "T23:59",
            questions: content.questions,
            submitting: true,
          },
        };

        db.collection("templates")
          .doc(String(newStateValue._id))
          .set(newStateValue)
          .then(() => {
            Notiflix.Loading.Remove();
            Notiflix.Report.Init({
              width: "400px",
              titleFontSize: "20px",
              messageFontSize: "17px",
              success: { buttonBackground: "#EE3A2C" },
            });
            Notiflix.Report.Success(
              "Successfully Pushed!",
              "This survey has been pushed as a template",
              "ok"
            );
          })
          .catch((error) => {
            alert(error);
          });
      });
  };

  render() {
    let surveyList = <Spinner />;
    if (!this.props.loading) {
      surveyList = this.props.groupedSurveys.map((survey, i) => (
        <Accordion key={i}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>{survey.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {this.props.role === "Admin" ? (
              <label className="CheckAllText">
                <Checkbox
                  checked={
                    this.state.selectedSurveys[i] &&
                    this.state.selectedSurveys[i].length == survey.data.length
                      ? true
                      : false
                  }
                  style={{ height: "fit-content", marginRight: 20 }}
                  onChange={() =>
                    this.onSelectSurvey("", actionTypes.SELECT_ALL, i, survey)
                  }
                  color="#34baf2"
                />
                <h4>
                  {this.state.selectedSurveys[i] &&
                  this.state.selectedSurveys[i].length == survey.data.length
                    ? "Unselect All Surveys"
                    : "Select All Surveys"}
                </h4>
              </label>
            ) : null}
            <div className={classes.accordionContainer}>
              {survey.data.map((s) => (
                <SurveyItem
                  key={s._id}
                  title={s.content.title}
                  creatorDate={s.content.creatorDate}
                  lastModified={s.content.lastModified}
                  openTill={s.content.openTill}
                  open={() => this.props.onGetSurveyId(s._id)}
                  delete={() => this.deleteSurveyHandler(s._id)}
                  clone={() =>
                    this.cloneSurveyHandler(s._id, this.props.compId)
                  }
                  pushtemplate={() =>
                    this.pushTemplateHandler(s._id, this.props.compId)
                  }
                  submitting={s.content.submitting}
                  surveyId={s._id}
                  checked={
                    this.state.selectedSurveys[i] &&
                    this.state.selectedSurveys[i].indexOf(s._id) !== -1
                      ? true
                      : false
                  }
                  onSelectSurvey={() =>
                    this.onSelectSurvey(s._id, "", i, survey)
                  }
                  role={this.props.role}
                />
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      ));
    }
    return (
      <div className="SurveyListContainer">
        {this.state.showModal ? (
          <CompanyModal
            companyListAsAdmin={this.state.companyListAsAdmin}
            onSubmit={this.handleSubmit}
            showModal={this.state.showModal}
            handleModalClose={this.handleModalClose}
            handleModalOpen={this.handleModalOpen}
          />
        ) : null}
        <div
          style={{ width: "100%", textAlign: "right", marginBottom: "20px" }}
        >
          {this.extractSelectedSurveys().length > 0 ? (
            <Button
              variant="success"
              style={{
                padding: "10px",
                marginRight: "15px",
                height: "fit-content",
                border: "none",
              }}
              onClick={this.handleSendDialogOpen}
            >
              Email Consolidated Survey Links
            </Button>
          ) : null}
          <Button
            variant="primary"
            style={{
              padding: "10px",
              marginRight: "15px",
              backgroundColor: "#34baf2",
              borderColor: "#34baf2",
            }}
            onClick={this.handleAuditDialogOpen}
          >
            Consolidated Mail Audit
          </Button>
        </div>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.auditDialogOpen}
          onClose={this.handleAuditDialogClose}
        >
          <DialogTitle>Consolidated Mail Audit</DialogTitle>
          <DialogContent>
            <p>
              The following data shows the mail audits for all the consolidated
              survey invites sent
            </p>
            <ConsolidatedAudit
              surveys={this.props.surveys}
              compid={this.props.compId}
            />
          </DialogContent>
          <DialogActions>
            <button
              onClick={this.handleAuditDialogClose}
              style={{
                border: "none",
                backgroundColor: "#D64B46",
                color: "white",
                padding: 10,
              }}
            >
              Cancel
            </button>
          </DialogActions>
        </Dialog>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.senddialogopen}
          onClose={this.handleSendDialogClose}
        >
          <DialogTitle className={classes.DialogTitle}>
            Email Consolidated Survey Links
          </DialogTitle>
          <DialogContent>
            <p>
              Enter the following details to send a single link to a director
              which when opened will present all the surveys selected by you
            </p>
            <p>
              <b>
                {" "}
                No. Of Selected Surveys: {this.extractSelectedSurveys().length}
              </b>
            </p>
            <div>
              <label style={{ color: "#9E9E9E", marginTop: "5px" }}>
                {" "}
                Group{" "}
              </label>
              <br />
              <select
                style={{ borderCollapse: "#34baf2", padding: 10 }}
                value={this.state.activeEmailGroupId}
                onChange={(e) => this.handleEmailGroup(e.target.value)}
              >
                <option value={""}>Select</option>
                {this.props.emailGroups.map((emailGroup) => (
                  <option key={emailGroup.id} value={emailGroup.id}>
                    {emailGroup.name}
                  </option>
                ))}
              </select>
              <div>
                <label style={{ color: "#9E9E9E", marginTop: "5px" }}>
                  {" "}
                  Email Addresses{" "}
                </label>
                <br />
                <ReactMultiEmail
                  placeholder="Enter Recipients"
                  style={{
                    width: "67vw",
                    border: "2px solid #34baf2",
                    borderRadius: "3px",
                    minWidth: "200px",
                    minHeight: "30px",
                    marginTop: "2px",
                    marginBottom: "15px",
                    fontSize: "20px",
                    padding: "7px",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                  emails={this.state.emails}
                  onChange={(_emails) => {
                    this.setState({ ...this.state, emails: _emails });
                  }}
                  validateEmail={(email) => {
                    return isEmail(email); // return boolean
                  }}
                  getLabel={(email, index, removeEmail) => {
                    return (
                      <div data-tag key={index}>
                        <p
                          style={{
                            fontSize: "17px",
                            marginTop: "13px",
                            color: "#000",
                          }}
                        >
                          {email}
                        </p>
                        <span
                          data-tag-handle
                          onClick={() => removeEmail(index)}
                          style={{ fontSize: "20px" }}
                        >
                          ×
                        </span>
                      </div>
                    );
                  }}
                />
              </div>
            </div>
            <div>
              <label style={{ color: "#9E9E9E", marginTop: "5px" }}>
                {" "}
                Subject{" "}
              </label>
              <br />
              <input
                value={this.state.subject}
                style={{
                  width: "100%",
                  padding: "10px",
                  border: "2px solid #34baf2",
                  borderRadius: "3px",
                }}
                onChange={(e) =>
                  this.setState({ ...this.state, subject: e.target.value })
                }
              />
            </div>

            <div>
              <label style={{ color: "#9E9E9E", marginTop: "5px" }}>
                {" "}
                Attachments{" "}
              </label>
              <br />
              <input
                type="file"
                id="mailFiles"
                name="attachment"
                style={{
                  width: "100%",
                  padding: "10px",
                  border: "2px solid #34baf2",
                  borderRadius: "3px",
                }}
                onChange={(e) => this.uploadFile(e.target.files)}
              />
              {this.state.attachments.map((file, i) => (
                <div>
                  {" "}
                  <FontAwesomeIcon
                    icon={faPaperclip}
                    style={{ color: "#BFC3C5" }}
                  ></FontAwesomeIcon>
                  <label
                    className={classes.FileItem}
                    onClick={() => this.downloadFile(file.link)}
                  >
                    {" "}
                    {file.name}{" "}
                  </label>
                  <span
                    className={classes.DeleteFile}
                    onClick={() => this.removeFile(i)}
                  >
                    {" "}
                    x{" "}
                  </span>{" "}
                </div>
              ))}
            </div>

            <div>
              <label style={{ color: "#9E9E9E", marginTop: "5px" }}>
                {" "}
                Message{" "}
              </label>
              <br />
              <textarea
                value={this.state.message}
                style={{
                  width: "100%",
                  padding: "10px",
                  border: "2px solid #34baf2",
                  borderRadius: "3px",
                }}
                onChange={(e) =>
                  this.setState({ ...this.state, message: e.target.value })
                }
                rows={3}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <button
              onClick={this.handleSendDialogClose}
              style={{
                border: "none",
                backgroundColor: "#D64B46",
                color: "white",
                padding: 10,
              }}
            >
              Cancel
            </button>
            <button
              onClick={this.sendEmail}
              className={classes.CreateButton}
              style={{
                border: "none",
                backgroundColor: "#28a745",
                color: "white",
                padding: 10,
                marginLeft: 10,
              }}
            >
              Mail Link
            </button>
          </DialogActions>
        </Dialog>
        {surveyList}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    surveys: state.surveys.surveys,
    groupedSurveys: state.surveys.groupedSurveys,
    compId: state.surveyEditer.company.id,
    loading: state.surveys.loading,
    token: state.auth.token,
    userId: state.auth.userId,
    emailGroups: state.surveyEditer.company.emailGroups,
    compname: state.surveyEditer.company.name,
    role: state.surveyEditer.company.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchSurveys: (token, userId) => dispatch(fetchSurveys(token, userId)),
    onGetSurveyId: (surveyId) =>
      dispatch({ type: actionTypes.GET_SURVEY_ID, surveyId: surveyId }),
    setEmailGroups: (emailGroups) =>
      dispatch({ type: "SET_EMAIL_GROUPS", emailGroups }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SurveyList)
);
